<template>
  <b-modal id="modal-share-file-wg" no-close-on-backdrop hide-header hide-footer centered>
    <div class="share-modal-container">
        <div class="d-flex justify-content-between align-items-center">
            <span class="title-modal-share">Share</span>
            <!-- <pre>{{item}}</pre> -->
            <img src="@/assets/images/icon/Close.svg" class="cursor-pointer" alt="" @click="close()">
        </div>
        <div class="my-2 selected-file-share">
            <img :src="require(`@/assets/images/icon/${item.icon}`)" alt/>
            <span class="ml-2 text-wrap-v3" data-toggle="tooltip" :title="item.file_name">{{ item.file_name }}</span>
            <div>
            </div>
        </div>
        <div id="horizontal-share-file">
            <hr class="horizontal-shared-file">
        </div>
        <div id="get-link-file-container-v3" class="mt-3 justify-content-start align-items-start flex-column">
            <span class="title-modal-share">Share via link</span>
            <div class="my-2 justify-content-start align-items-center">
                <label class="switch-share-v3">
                    <input
                        id="checkShareValueWorkgroup"
                        type="checkbox"
                        v-model="enableds"
                        @click="showLink()">
                    <span class="slider-share-v3 round-share-v3"></span>
                </label>
                <span class="ml-2 label-toggle-share">{{ enableds == false ? 'Disabled' : 'Enabled'}}</span>
            </div>
            <div class="share-link-file mt-2">
                <input
                    class="shared-link"
                    type="text"
                    :value="linkFile"
                    v-on:keydown="noBackSpace($event)"
                    v-if="enableds">
                <img
                    v-if="enableds"
                    src="@/assets/images/icon/fi-rr-copy-alt.svg"
                    alt=""
                    @click="copyLink(linkFile)"
                    data-toggle="tooltip"
                    title="Copy Link">
            </div>
        </div>
        <div id="send-inv-share-file" class="mt-2">
            <div class="d-flex justify-content-end align-items-center">
                <button class="skip-add-btn-v3 main-color-api" @click="close()">Cancel</button>
                <button
                  class="ml-3 inv-btn-participant-v3 main-btn-api"
                  @click="sendBtn()"
                  :disabled="this.tagsEmail.length <= 0">Send</button>
            </div>
        </div>
    </div>
  </b-modal>
</template>

<script>
export default {
    data() {
        return {
            tagsEmail: [],
            // isChecked: false,
            inputModel: '',
            accessTypes : [
                {
                    id : "View",
                    label : "Viewer",
                    selected : true
                },
                {
                    id : "Write",
                    label : "Editor",
                    selected : false
                }
            ],
        }
    },
    computed: {
        state(){
            return this.$store.getters['state/state'];
        },
        item(){
            return this.$store.getters["workgroup/item"];
        },
        linkFile(){
            return location.protocol + '//' + location.host+"/shared/?key="+this.item._id+"&directory="+this.item.directory
        },
        selectedWorkgroup(){
            return this.$store.getters['workgroup/workgroup']
        },
        // selectedAccess(){
        //     return this.accessTypes.filter(v=>v.selected)[0]
        // },
        enableds:{
            cache: false,
            get(){
                return this.$store.getters["workgroup/publicShare"];
            },
            set(){
                const enbld = this.$store.getters["workgroup/publicShare"];
                this.$store.commit("workgroup/SET_PUBLIC_SHARE",enbld);
            }
        }
    },
    methods: {
        showLink(){
            var checkBox = document.getElementById('checkShareValueWorkgroup')
            if(checkBox.checked){
                this.$store.dispatch("share/public_share",
                    {
                        files_id : this.item._id,
                        directory : this.item.directory
                    }
                )
                .then(()=>{
                    this.$store.commit("workgroup/SET_PUBLIC_SHARE",true);
                    this.$store.dispatch("workgroup/fileretrieve", {id : this.item._id, workgroup_id: this.selectedWorkgroup._id})
                    this.$toasted.show(`File shared successfully.`,{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
            } else {
                this.$store.dispatch("share/disable_public_share",
                    {
                        files_id : this.item._id,
                        directory : this.item.directory
                    }
                ).then(()=>{
                    this.$store.commit("workgroup/SET_PUBLIC_SHARE",false);
                    this.$store.dispatch("workgroup/fileretrieve", {id : this.item._id, workgroup_id: this.selectedWorkgroup._id})
                })
            }
        },
        noBackSpace(e) {
            if(e.key != 'ArrowLeft' && e.key != 'ArrowRight' && e.key != 'Shift'){
                e.preventDefault()
            }
        },
        copyLink(value) {
            navigator.clipboard.writeText(value)
            this.$toasted.show('Link copied.',{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000
            })
        },
        close() {
            this.$store.dispatch("workgroup/listing")
            .then(()=>{
                this.$bvModal.hide('modal-share-file-wg')
            })
            .catch(()=>{})
        }
    }
}
</script>

<style>
#send-inv-share-file{
    display: none;
}
#dropdown-share-file-participant{
    width: fit-content;
}
#dropdown-share-file-participant button{
    background: #FFFFFF !important;
    color: #262A2C !important;
    border: none;
    margin: 12px 0 12px 0;
    padding: 0;
}

hr.horizontal-shared-file{
    border: 1px solid #E8E8E8;
    margin: 0;
}

.emails-input-share-container{
    border: 1px solid #E9EAEA;
    padding: 16px;
    border-radius: 4px;
    display: flex;
    width: 384px;
    height: 52px;
    background: #FFFFFF;
    flex-wrap: wrap;
    overflow-y: scroll;
    overflow-x: hidden;
}
.h-132{
    height: 132px;
}
.h16-v3{
    height: 16px;
}

.emails-input-share-container:focus{
    border: 2px solid #00AAFF;
}
.emails-input-share-container .tag{
  padding: 4px 8px;
  border: none;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #E9F5FB;
  margin: 0 8px 8px 0;
  cursor: default;
  font-size: 16px;
  line-height: 24px;
  color: #262A2C;
  font-weight: 400;
  letter-spacing: -1px;
}
.emails-input-share-container .error-tag{
  background: #FBE9E9 !important;
}

.share-modal-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title-modal-share{
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
    color: #262A2C;
}
.selected-file-share{
    background: #F6F6FC;
    border-radius: 64px;
    padding: 8px 16px;
    width: fit-content;
    display: flex;
}
.selected-file-share span{
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    color: #262A2C;
}
.text-wrap-v3{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 320px;
}
.share-link-file{
    position: relative;
}
.share-link-file img{
    position: absolute;
    top: 15px;
    right: 17px;
    width: 20px;
    cursor: pointer;
}
.shared-link{
    min-width: auto;
    width: 384px;
    height: 52px;
    padding: 16px 55px 16px 16px;
    border: 1px solid #E9EAEA;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    color: #262A2C;
}
.shared-link:focus{
    outline: none;
}

/* Toggle switch */
.switch-share-v3 {
  position: relative;
  /* display: inline-block; */
  width: 51px;
  height: 31px;
}

/* Hide default HTML checkbox */
.switch-share-v3 input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider-share-v3 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(120, 120, 128, 0.16);
  -webkit-transition: .4s;
  transition: .4s;
}

.slider-share-v3:before {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.switch-share-v3 input:checked + .slider-share-v3 {
  background-color: #34C759;
}

.switch-share-v3 input:focus + .slider-share-v3 {
  box-shadow: 0 0 1px #34C759;
}

.switch-share-v3 input:checked + .slider-share-v3:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider-share-v3.round-share-v3 {
  border-radius: 34px;
}

.slider-share-v3.round-share-v3:before {
  border-radius: 50%;
}
.label-toggle-share{
    position: relative;
    color: #919394;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1px;
    font-weight: 300;
    top: 7px;
}
</style>