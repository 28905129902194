<template>
    <div class="d-flex justify-content-center flex-column align-items-center suspends">
        <img src="@/assets/images/suspend-img.svg" style="height:128px; width: 128px;" class="my-3" alt="">
        <span class="mb-2">This workgroup is no longer accessible</span>
        <span class="mb-3"><span>You can access this workgroup again once the admin updates the payment method or renews the plan.</span></span>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>