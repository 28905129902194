
<script>
export default {
    computed:{
        item(){
            return this.$store.getters["storage/item"];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        state(){
            return this.$store.getters['state/state'];
        }
    },
    data() {
        return {
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            counter : 0,
            loading : false,
            deleteCount : 0
        };
    },
    methods:{
      cancel(){
          this.$root.$emit('bv::hide::modal', 'modaltoTrash');
      },
      toTrash(id){
        this.loading = true
        this.$toasted.show(`Moving 1 item to trash...`,{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : this.loading == true,
            queue: true
        })
        this.$store.dispatch(this.state+"/deleteFile",id)
        .then(()=> {
            this.$toasted.show(`1 item moved to trash successfully.`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 2000,
                queue: true
            })
            this.loading = false
            this.$store.dispatch(this.state+"/listing")
            this.$root.$emit('bv::hide::modal', 'modaltoTrash');
        })
        .catch((err)=>{
        })
      },
      multiToTrash(){
        this.loading = true
        this.$toasted.show(`Moving ${this.selectedField.length} item${this.selectedField.length > 1 ? "s" : ""} to trash...`,{ 
            theme: "toasted-primary", 
            position: "bottom-center", 
            duration : 1000,
            queue: true
        })
        var promise = this.selectedField.map((v)=>{
            return this.$store.dispatch(this.state+"/deleteFile",v._id)
                .then(() => {
                    
                    this.deleteCount++

                })
                .catch(() => {
                    this.$toasted.show(`Item failed to move. Make sure your internet is connected, then try again.`,{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                })
        })
        
        Promise.all(promise)
            .then(()=>{
                this.$toasted.show(`${this.deleteCount} item${this.deleteCount > 1 ? "s" : ""} moved to trash successfully.`,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    queue: true
                })

                this.deleteCount = 0

                this.loading = false
                this.$store.dispatch(this.state+"/listing")
                this.$root.$emit('bv::hide::modal', 'modaltoTrash');
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
            })
            .catch((err)=>{
                this.loading = false
                this.$store.dispatch(this.state+"/listing")
                this.$root.$emit('bv::hide::modal', 'modaltoTrash');
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
            })
      }
    }
};
</script>
<template>
    <b-modal id="modaltoTrash" no-fade  content-class="shadow" :hide-header="true"  :hide-footer="true"  centered title="Move 1 item?">
        <div v-if="item != null && selectedField.length == 0">
            <h2 class="modal-title text-dark bold-normal default-text-style">Move 1 item?</h2>
            <p class="mt-3 default-text-style" v-if="state == 'storage'">This item will be moved to the trash.</p>
            <div class="d-flex justify-content-end align-items-center close-and-submit-enterprise">
                <span @click="loading != true ? cancel() : ''">Cancel</span>
                <button :disabled="loading == true" @click="toTrash(item._id)">
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                    Move
                </button>
            </div>
        </div>
        <div v-if="selectedField.length > 0">
            <h2 class="modal-title text-dark bold-normal default-text-style">Move {{selectedField.length}} item{{selectedField.length > 1 ? "s" : ""}}?</h2>            
            <p class="mt-3 default-text-style">
                {{selectedField.length > 1 ? "These items" : "This item"}} will be moved to the trash.
            </p>
            <div class="d-flex justify-content-end align-items-center close-and-submit-enterprise">
                <span class="mr-3" @click="loading != true ? cancel() : ''">Cancel</span>
                <button class="btn-delete" :disabled="loading == true" @click="multiToTrash()">
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                    Move to Trash
                </button>
            </div>
        </div>
    </b-modal>
</template>