/* eslint-disable vue/no-side-effects-in-computed-properties */
<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Sekeleton from "../../../components/widgets/loadingtable";
import Move from "../../../components/widgets/move.vue";
import Copy from "../../../components/widgets/copy.vue";
import CopyPersonal from "../../../components/widgets/copyPersonal.vue";
import CopyWorkgroup from "../../../components/widgets/copyWorkgroup.vue";
import Share from "../../../components/widgets/share.vue";
import Download from "../../../components/widgets/download.vue";
import ImageView from "../../../components/widgets/imageView.vue";
import VideoView from "../../../components/widgets/videoView.vue";
import AudioView from "../../../components/widgets/audioView.vue";
import toTrash from "../../../components/widgets/totrash.vue";
import Helper from "../../../api/helper";
import belowNav from '../../../components/belowNavbar.vue'
import Encrypt from '../../../components/encrypt.vue'
import Decrypt from '../../../components/decrypt.vue'
import ModalShare from '../../../components/widgets/modalShare.vue'
import ModalShareWorkgroup from '../../../components/widgets/modalShareWorkgroup.vue'
import ModalRename from '../../../components/widgets/modalRename.vue'
import ModalFolderShare from '../../../components/modalFolderShare.vue'
import ModalSendToChat from '../chat/modalSendFiles.vue'

import SuspendPage from '../utility/suspend.vue'
import SuspendWorkgroupPage from '../storage/settings/suspendWorkgroup.vue'


/**
 * Dashboard Component
 */
export default {
  page: {
    title: "My Data",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    Layout,
    Sekeleton,
    Move,
    Copy,
    CopyPersonal,
    CopyWorkgroup,
    Share,
    Download,
    ImageView,
    VideoView,
    AudioView,
    toTrash,
    belowNav,
    Encrypt,
    Decrypt,
    ModalShare,
    ModalShareWorkgroup,
    ModalRename,
    ModalFolderShare,
    ModalSendToChat,
    SuspendPage,
    SuspendWorkgroupPage
  },
  data() {
    return {
      title: "My Data",
      sortName : false,
      counter: 0,
      valueRename : "",
      // showProperties : false,
      sort : "ASC",
      allchecked : false,
      item : {isFolder:false},
      folderToUpload : null,
      idDrag : "",
      clickTimer : null,
      isDropdown2Visible: false,
      isDropdown3Visible: false,
      isDropdown4Visible: false
    }
  },
  computed: {
    isWorkgroupSuspend() {
      return this.$store.getters['workgroup/statusSuspendWorkgroup']
    },
    isSuspend() {
      return this.$store.getters['user/accountSuspend']
    },
    listings: {
      // getter
      get: function () {
        var data = this.$store.getters[this.$store.getters['state/state']+'/items']
        return this.$store.getters[this.$store.getters['state/state']+'/items'];
      },
      // setter
      set: function (newValue) {
        this.$store.commit(this.$store.getters['state/state']+"/SET_ITEMS",newValue);
      }
    },
    keyword(){
        return this.$store.getters[this.state+'/keyword'];
    },
    detail(){
        return this.$store.getters[this.$store.getters['state/state']+'/item'];
    },
    loading(){
      // return true
        return this.$store.getters[this.$store.getters['state/state']+'/loading'];
    },
    limit() {
      return this.$store.getters[this.$store.getters['state/state']+'/limit']
    },
    total(){
      return this.$store.getters[this.$store.getters['state/state']+'/totalItem'];
    },
    grid(){
        return this.$store.getters['storage/gridView'];
    },
    selectedField() {
        return this.$store.getters['storage/selected']
    },
    idUser(){
        return this.$store.getters['user/id']
    },
    state(){
        return this.$store.getters['state/state'];
    },
    canAccess(){
        var id = this.$store.getters['user/id'];
        var group = this.$store.getters['workgroup/workgroup'];
        var st = this.$store.getters['state/state'];
        if(st == "storage"){
          return true
        }else{
          if(id == group.owner_id){
            return true;
          }else{
            return group.access_type != "View";
          }
        }
    },
    selectedWorkgroup(){
      return this.$store.getters['workgroup/workgroup']
    },
    isOwner(){
      var id = this.$store.getters['user/id'];
      var group = this.$store.getters['workgroup/workgroup'];
      if(this.state == 'storage'){
        return true
      } else {
        if(group == null) {
          return true
        } else {
          return id == group.owner_id;
        }
      }
    }
  },
  beforeMount() {
    var sessionUser = sessionStorage.getItem('invitationEmail')
    var currentUser = JSON.parse(localStorage.getItem('user'))
    let joinedWorkgroupId = sessionStorage.getItem('joinedWorkgroup')
    if(sessionStorage.getItem('invitationEmail') != undefined){
      if (currentUser != null){
        if(currentUser[0].email != sessionUser){
          this.$router.push('/access-permission')
        } else {
          if(joinedWorkgroupId != undefined){
            this.$route.push(`/?wgId=${joinedWorkgroupId}`)
            sessionStorage.removeItem('joinedWorkgroup')
          }
        }
      }
    }
  },
  mounted(){
    // this.$ga.page('/')
    if(this.state == 'workgroup'){
      if(!this.$route.query.wgId){
        const currentPath = this.$route.path

        this.$router.push({ path: currentPath, query: {wgId: this.selectedWorkgroup._id}})
        .catch(err => {
            // Handle the navigation error (redundant navigation)
            if (err.name !== 'NavigationDuplicated') {
                throw err;
            }
        })
      }
      this.getWorkgroup()
    }
    this.$ga.event(process.env.VUE_APP_GA_PAGE_MY_FILES, 'Load Halaman MyFiles', 'personal-acces-file', 1)
    this.$store.commit("storage/SET_SHOW_PROPERTIES",false);
    this.$store.commit("share/SET_SHOW_PROPERTIES",false);
    this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
    this.$store.commit("storage/SET_ALL_SELECTED",[]);
    // this.$store.commit(this.state+"/SET_PARENT_ID","");
    this.$store.commit(this.state+"/SET_KEYWORD", '')
    const sideBarValue = this.$store.getters['storage/sideRightToggle'];
    this.$store.dispatch('storage/setSideRightToggle',{isShowing: sideBarValue, isPersistent: true});
    if(this.$store.getters["upload/files"].length > 0){
      document.getElementById("uploadLoading").style.display = "block";
    }else{
      document.getElementById("uploadLoading").style.display = "none";
    }
    this.$store.commit(this.state+"/SET_LIMIT", 50)
    this.$store.dispatch(this.state+"/listing");
    // this.$root.$emit('bv::show::modal', 'modalUpload')
    this.$store.commit(this.state+"/SET_SHOWBREADCUMB",true);
    this.$store.commit("storage/SET_SUBTITLE","Storage");

    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-4') {
        this.isDropdown4Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-4') {
        this.isDropdown4Visible = false;
      }
      if(this.isDropdown4Visible) {
        bvEvent.preventDefault()
      }
    })
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-3') {
        this.isDropdown3Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-3') {
        this.isDropdown3Visible = false;
      }
      if(this.isDropdown3Visible) {
        bvEvent.preventDefault()
      }
    })
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = true;
      }
    })
    this.$root.$on('bv::dropdown::hide', bvEvent => {
      if(bvEvent.componentId === 'dropdown-2') {
        this.isDropdown2Visible = false;
      }
      if(this.isDropdown2Visible) {
        bvEvent.preventDefault()
      }
    })
  },
  methods:{
      getClassStyle(data){
        if(data.checked == true) {
          if(this.selectedField.length < 2) {
            return 'more-btn-op-1 img-more-grid'
          } else {
            return 'img-more-grid-disable more-btn'
          }
        } else {
          return 'img-more-grid more-btn' 
        }
      },
      getWorkgroup(){
        this.$store.dispatch("workgroup/retrieve", this.selectedWorkgroup._id)
      },
      detailToggle2(item) {
        const sideBarValue = this.$store.getters['storage/sideRightToggle'];
        var showDetails = document.getElementById('show-details-info-sideRight')
        if(sideBarValue){
          this.$store.dispatch('storage/setSideRightToggle',{isShowing: false, isPersistent: false})
          if(!showDetails.classList.contains('show')){
            this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
          }
        } else{
          if(!showDetails.classList.contains('show')){
            this.$root.$emit('bv::toggle::collapse', 'show-details-info-sideRight')
          }
        }
        this.properties(item)
        if(item.isFolder == true){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DETAIL_FOLDER, 'Detail Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DETAIL_FOLDER, 'WG - Info Detail Folder', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DETAIL_FILE, 'Info File in Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DETAIL_FILE, 'WG - Info Detail File', 'wg-access-file', 1)
          }
        }
        this.hideContextMenu()
      },
      clickers(item){
        this.counter++
        if (this.counter === 1) {
          this.clickTimer = setTimeout(() => {
            this.listings = this.listings.map((v)=>{
              return {
                ...v,
                checked : v._id == item._id ? true : false 
              }
            })
            // let filteredArray = this.listings.filter(v => v.checked === true)
            // let count = filteredArray.length
            this.$store.commit("storage/SET_ONE_SELECTED",item)
            this.counter = 0
            this.properties(item)
          }, 300)
        } else if (this.counter === 2) {
          clearTimeout(this.clickTimer)
          this.counter = 0
          this.doubleClick(item)
        }
    
      },
      sorting(){
        this.sort =( this.sort == 'ASC') ? 'DESC' : 'ASC';
        this.$store.dispatch("storage/sorting",this.sort);
        this.hideContextMenu();
      },
      listingList(){
        this.$store.dispatch("storage/listing");
        this.hideContextMenu();
      },
      loadmore(){
        this.$store.dispatch(this.state+"/increamentItems");
        // this.$store.commit("storage/SET_LIMIT",this.total);
        this.$store.dispatch(this.state+"/listing");
        this.hideContextMenu();
      },
      doubleClick(item){
        if(!item.isFolder){
          if(item.locked == false){
            if(item.encrypt == null ||
              item.encrypt.code != 1 && item.encrypt != 1
              && item.encrypt.code != 2 && item.encrypt != 2
              && item.encrypt.code != 4 && item.encrypt != 4
              && item.encrypt.code != 6 && item.encrypt != 6
              && item.encrypt.code != 7 && item.encrypt != 7
            ){
              if(this.state == 'storage'){
                this.$ga.event(process.env.VUE_APP_GA_OPEN_FILE, 'Open File in Primary', 'personal-acces-file', 1)
              }else{
                this.$ga.event(process.env.VUE_APP_GA_WG_GF_OPEN_FILE, 'WG - Open File', 'wg-access-file', 1)
              }
              this.openFile(item);
            } else if(item.encrypt.code == 2 || item.encrypt == 2
              || item.encrypt.code == 6 || item.encrypt == 6
              || item.encrypt.code == 7 || item.encrypt == 7
            ){
              if(this.canAccess){
                this.sendInfoDecrypt(item)
              }
            }
          }
        }else{
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_OPEN_FOLDER, 'Open Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_OPEN_FOLDER, 'WG - Open Folder', 'wg-access-folder', 1)
          }
          this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
          // this.$store.commit(this.state+"/SET_BREADCUMB",item);
          this.$store.commit("storage/SET_ITEM",null)
          this.$store.commit(this.state+"/SET_KEYWORD", '')
          this.$store.dispatch(this.state+"/listing")
        }
        this.hideContextMenu();
      },
      rename(index){
        if(index == null){
          var item = this.item
          index = this.listings.findIndex((v)=>{
            return v._id == item._id
          })
          this.valueRename = this.listings[index].isFolder ? this.listings[index].file_name : this.listings[index].file_name.split('.').slice(0, -1).join('.');
          this.$store.commit(this.state+"/SET_RENAME",index);
          this.hideContextMenu();
        } else {
          this.valueRename = this.listings[index].isFolder ? this.listings[index].file_name : this.listings[index].file_name.split('.').slice(0, -1).join('.');
          this.$store.commit(this.state+"/SET_RENAME",index);
          this.hideContextMenu();
        }
      },
      submitRename(index){
        this.valueRename = this.listings[index].isFolder ? this.valueRename : this.valueRename+"."+this.listings[index].file_name.substr(this.listings[index].file_name.lastIndexOf('.') + 1);
        this.$store.dispatch(this.state+"/rename",{id:this.listings[index]._id,file_name:this.valueRename})
        this.$store.commit(this.state+"/SET_RENAME",index);
        this.hideContextMenu();
      },
      sendToChat(item){
        if(item == null){item = this.item}
        // this.item = item
        this.$ga.event(process.env.VUE_APP_GA_WG_GF_SEND_FILE_TO_CHAT, 'WG - Send File to Chat', 'wg-access-file')
        this.$root.$emit('bv::show::modal', 'modal-send-to-chat')
        this.properties(item)
        this.hideContextMenu()
      },
      move(item){
        if(item == null){item = this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_MOVE_FOLDER, 'Move Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_MOVE_FOLDER, 'WG - Move Folder', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_MOVE_FILE, 'Move File in Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_MOVE_FILE, 'WG - Move File', 'wg-access-file', 1)
          }
        }
        this.$store.commit(this.state+"/SET_ITEM",item);
        this.$store.commit(this.state+"/SET_HASHMOVE",item.hash_name);
        this.$store.commit(this.state+"/SET_KEYWORD","");
        this.$store.commit(this.state+"/SET_PARENT_ID","");
        this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
        this.$store.dispatch(this.state+"/listing", 'noclear')
        this.$root.$emit('bv::show::modal', 'modalMove');
        this.hideContextMenu();
      },
      copy(item){
        if(item == null){item = this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_COPY_FOLDER_WORKGROUP, 'actionx', 'labelx', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_COPY_FOLDER_IN_WG, 'WG - Copy Folder in the Workgroup', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_COPY_FILE_WORKGROUP, 'actionx', 'labelx', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_COPY_IN_WG, 'WG - Copy File in the Workgroup', 'wg-access-file', 1)
          }
        }
        this.$store.commit(this.state+"/SET_ITEM",item);
        this.$store.commit(this.state+"/SET_HASHMOVE",item.hash_name);
        this.$store.commit(this.state+"/SET_KEYWORD","");
        this.$store.commit(this.state+"/SET_PARENT_ID","");
        this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
        this.$store.dispatch(this.state+"/listing", 'noclear')
        this.$root.$emit('bv::show::modal', 'modalCopy');
        this.hideContextMenu();
      },
      copyPersonal(item){
        if(item == null){item = this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_COPY_FOLDER_PERSONAL, 'Copy Folder in the Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_COPY_FOLDER_OUT_PERSONAL, 'WG - Copy Folder Workgroup to Personal', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_COPY_FILE_PERSONAL, 'Copy File in the Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_COPY_OUT_PERSONAL, 'WG - Copy File Workgroup to Personal', 'wg-access-file', 1)
          }
        }
        this.$store.commit("storage/SET_ITEM",item);
        this.$store.commit("storage/SET_HASHMOVE",item.hash_name);
        this.$store.commit("storage/SET_KEYWORD","");
        this.$store.commit("storage/SET_PARENT_ID","");
        this.$store.commit("storage/DELETE_BREADCUMB",[]);
        this.$store.dispatch("storage/listing", 'noclear')
        this.$root.$emit('bv::show::modal', 'modalCopyPersonal');
        this.hideContextMenu();
      },
      copyWorkgroup(item){
        if(item == null){item = this.item}
        if(item.isFolder == true){
          this.$ga.event(process.env.VUE_APP_GA_COPY_FOLDER_WORKGROUP, 'Copy Folder Personal to Workgroup', 'personal-acces-folder', 1)
        } else {
          this.$ga.event(process.env.VUE_APP_GA_COPY_FILE_WORKGROUP, 'Copy File Primary to Workgroup', 'personal-acces-file', 1)
        }
        this.$store.commit("workgroup/SET_ITEM",item);
        this.$store.commit("workgroup/SET_HASHMOVE",item.hash_name);
        this.$root.$emit('bv::show::modal', 'modalCopyWorkgroup');
      },
      // shareopen(item){
      //   if(item == null){item=this.item}
      //   this.$store.commit("storage/SET_ITEM",item);
      //   this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
      //   this.$root.$emit('bv::show::modal', 'modalShare');
      //   this.hideContextMenu();
      // },
      sharefolder(item){
        if(item == null){item = this.item}
        this.$root.$emit('bv::show::modal', 'modal-folder-share-v3');
      },
      shareopennew(item){
        if(item == null){item=this.item}
        this.$store.commit("storage/SET_ITEM",item);
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
        .then(() => {
          this.$root.$emit('bv::show::modal', 'modal-share-file-v3')
        })
        this.hideContextMenu();
      },
      shareOpenNewWg(item){
        if(item == null){item=this.item}
        this.$store.commit("workgroup/SET_ITEM",item);
        this.$store.dispatch("workgroup/fileretrieve", {id : item._id, workgroup_id: this.selectedWorkgroup._id})
        .then(() => {
          this.$root.$emit('bv::show::modal', 'modal-share-file-wg')
        })
        this.hideContextMenu();
      },
      openDownload(item){
        if(item == null){item=this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DOWNLOAD_FOLDER, 'Download Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DOWNLOAD_FOLDER, 'WG - Download Folder ', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DOWNLOAD_FILE, 'Download File in Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DOWNLOAD_FILE, 'WG - Download File', 'wg-access-file', 1)
          }
        }
        this.$store.commit("storage/SET_ITEM",item);
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
        .then(()=>{
          this.$root.$emit('bv::show::modal', 'modalDownload');
        });
        this.hideContextMenu();
      },
      renameItem(item){
        if(item == null){item = this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_RENAME_FOLDER, 'Rename Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_RENAME_FOLDER, 'WG - Rename Folder', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_RENAME_FILE, 'Rename File in Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_RENAME_FILE, 'WG - Rename File', 'wg-access-file', 1)
          }
        }
        this.$store.commit(this.state+"/SET_ITEM",item)
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
            .then(()=> {
                this.$root.$emit('bv::show::modal', 'modal-rename-v3')
            })
        this.properties(item)
        this.hideContextMenu()
      },
      sendInfoEncrypt(item){
        if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_ENCRYPT_FILE, 'Encrypt File in Primary', 'personal-acces-file')
        } else {
          this.$ga.event(process.env.VUE_APP_GA_WG_GF_ENCRYPT_FILE, 'WG - Encrypt File', 'wg-access-file')
        }
        this.item = item
        this.$root.$emit('bv::show::modal', 'modal-encrypt')
        this.properties(item)
        this.hideContextMenu();
      },
      sendInfoDecrypt(item){
        if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_DECRYPT_FILE, 'Decrypt File in Primary', 'personal-acces-file')
        } else {
          this.$ga.event(process.env.VUE_APP_GA_WG_GF_DECRYPT_FILE, 'WG - Decrypt File', 'wg-access-file')
        }
        this.item = item
        this.$root.$emit('bv::show::modal', 'modal-decrypt')
        this.properties(item)
        this.hideContextMenu();
      },
      properties(item){
        if(item == null){item = this.item}
          this.$store.commit("storage/SET_ITEM",item);
          this.$store.commit("storage/SET_SHOW_PROPERTIES",true);
          this.$store.commit("workgroup/SET_SHOW_PARTICIPANTS",false);
          // var header = document.getElementById("page-topbar");
          // header.style.right = "400px"; 
          // var mainpage = document.getElementById("main-page");
          // mainpage.style.marginRight = "410px";
          if(this.state == 'storage'){
            this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory});
          } else {
            this.$store.dispatch("workgroup/fileretrieve", {id : item._id, workgroup_id: this.selectedWorkgroup._id})
          }
          this.showProperties = true;
          // this.hideContextMenu();
      },
      deleteFile(item){
        if(item == null){item=this.item}
        if(item.isFolder == true){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DELETE_FOLDER, 'Delete Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DEL_FOLDER, 'WG - Del Folder', 'wg-access-folder', 1)
          }
        } else {
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_DELETE_FILE, 'Delete File in Primary', 'personal-acces-file', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_DEL_FILE, 'WG - Del File', 'wg-access-file', 1)
          }
        }
        this.$store.commit(this.state+"/SET_ITEM",item);
        this.$store.dispatch("storage/fileretrieve",{_id : item._id,directory:item.directory})
        .then(()=>{
          this.$root.$emit('bv::show::modal', 'modaltoTrash');
        });
        this.hideContextMenu();
      },
      openFile(list){
        if(list == null){list=this.item}
        this.$store.commit("storage/SET_ITEM",list);
        // this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
        // .then((res)=>{
        //   console.log('list', res)
        // });
        if(list.isFolder){
          if(this.state == 'storage'){
            this.$ga.event(process.env.VUE_APP_GA_OPEN_FOLDER, 'Open Folder in Primary', 'personal-acces-folder', 1)
          } else {
            this.$ga.event(process.env.VUE_APP_GA_WG_GF_OPEN_FOLDER, 'WG - Open Folder', 'wg-access-folder', 1)
          }
          this.$store.commit(this.state+"/SET_PARENT_ID",list._id);
          // this.$store.commit(this.state+"/SET_BREADCUMB",list);
          this.$store.commit(this.state+"/SET_KEYWORD", '')
          this.$store.dispatch(this.state+"/listing")
        }else{
          if(Helper.supportOnlyOffice(list.extension.toLowerCase())){
              if(this.canAccess){
                this.$store.commit("storage/SET_CAN_ACCESS_FILE", true)
                localStorage.setItem("access_type", JSON.stringify("edit"))
              }else{
                this.$store.commit("storage/SET_CAN_ACCESS_FILE", false)
                localStorage.setItem("access_type", JSON.stringify("view"))
              }
              this.$store.dispatch("storage/open",list)
              .then(()=>{
                if(this.state == "storage"){
                  this.$router.push(`/viewer/?id_f=${list._id}&dir_f=${list.directory}`)
                } else {
                  this.$router.push(`/viewer/?id_f=${list._id}&dir_f=${list.directory}&wgId=${this.selectedWorkgroup._id}`)
                }
              })
              
          }else if(Helper.isImages(list.extension.toLowerCase())){
              this.$store.dispatch("storage/getLinkFile",list);
              this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory});
                let imageView = document.getElementById("imageView");
                imageView.style.display = 'block';
          }else if(Helper.isVideos(list.extension.toLowerCase())){
              this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
              .then(c=>{
                let video = document.getElementById("VideoView");
                video.style.display = 'block';
                this.$store.dispatch("storage/getLinkFile",c)
                .then((v)=>{
                  let videoPlayer = document.getElementById("videoPlayer");
                  videoPlayer.src = v;
                  videoPlayer.play();
                });
              });
          }else if(Helper.isAudios(list.extension.toLowerCase())){
              this.$store.dispatch("storage/fileretrieve",{_id : list._id,directory:list.directory})
              .then(c=>{
                let audio = document.getElementById("AudioView");
                audio.style.display = 'block';
                this.$store.dispatch("storage/getLinkFile",c)
                .then((v)=>{
                  let audioPlayer = document.getElementById("audioPlayer");
                  audioPlayer.src = v;
                  audioPlayer.play();
                });
              });
          }else{
            this.$store.dispatch("storage/download",
            {
                directory : list.directory,
                hash_name : list.hash_name,
                file_name : list.file_name
            }
            );
          }
          // this.$store.commit(this.state+"/SET_KEYWORD", '')
        }
        this.hideContextMenu();
      },
      uploadFile(){
        if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_UPLOAD_FILES, 'Click Upload File Blank State', 'personal-acces-file', 1)
        } else {
          this.$ga.event(process.env.VUE_APP_GA_WG_GF_UPLOAD_FILE_BLANK_STATE, 'WG - Click Upload File Blank State', 'wg-access-file', 1)
        }
        // this.$refs.layout.$refs.sidebar.$refs.sidenav.uploadFile();
        // this.hideContextMenu();
        document.getElementById("fileUpload").click()
      },
      createFolder(){
        if(this.state == 'storage'){
          this.$ga.event(process.env.VUE_APP_GA_CREATE_FOLDER, 'Click Create Folder Blank State', 'personal-acces-file', 1)
        } else {
          this.$ga.event(process.env.VUE_APP_GA_WG_GF_CREATE_FOLDER_BLANK_STATE, 'WG - Create Folder Blank State', 'wg-access-file', 1)
        }
        this.$root.$emit('bv::show::modal', 'modalContainer')
      },
      fileDragIn(e,id,list){
        if(list.isFolder){
          var overlayDrag = document.getElementById(id);
          overlayDrag.classList.add("active-drag")
          var modalDrag = document.getElementById("drag-modal");
          modalDrag.classList.add("show")
          this.folderToUpload = list
          this.idDrag = id
          this.hideContextMenu();
        }
      },
      fileDragOut(a,id,list){
        if(list.isFolder){
          var overlayDrag = document.getElementById(id);
          overlayDrag.classList.remove("active-drag")
          var modalDrag = document.getElementById("drag-modal");
          modalDrag.classList.remove("show")
          this.folderToUpload = null
          this.idDrag = ""
          this.hideContextMenu();
        }
      },
      dropFile(ev,id,list){
          // Prevent default behavior (Prevent file from being opened)
          var overlayDrag = document.getElementById(id);
          overlayDrag.classList.remove("active-drag")
          var modalDrag = document.getElementById("drag-modal");
          modalDrag.classList.remove("show")
          this.$store.commit(this.state+"/SET_PARENT_ID",list._id);
          document.getElementById("uploadLoading").style.display = "block";
          let promise = [];
          ev.dataTransfer.files.forEach((file)=>{
            var filesData = {
              id : (Math.random() + 1).toString(36).substring(2),
              name : file.name,
              done : false,
              status : 2,//1=prepare,2=uploading,3=done,4=failed,
              size : file.size,
              type : file.type,
              percentage : 0,
              error : "",
              isFolder : false,
              total : 1,
              uploaded : 0
            }
            this.$store.commit("upload/ADD_FILES",filesData);
            var metadata = {
                  filename: file.name,
                  filetype: file.name.split('.').pop()
            }
            promise.push(
              this.$store.dispatch(this.state+"/uploadTus",{
                file : file,
                metadata : metadata,
                filesData : filesData,
                isFolder : false
              })
            );
          });
          Promise.all(promise).then(()=>{
            this.$store.commit(this.state+"/SET_PARENT_ID",list._id);
            // this.$store.commit(this.state+"/SET_BREADCUMB",list);
            this.$store.dispatch(this.state+"/listing")
          });
          ev.preventDefault();
      },
      changeChecked(){
        if(this.canAccess){
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : this.allchecked 
            }
          })
          this.$store.commit("storage/SET_ALL_SELECTED",this.allchecked ? this.listings : []);
        }
        // this.hideContextMenu();
      },
      checked(ActiveSelected){
        if(this.canAccess){
          if(ActiveSelected.checked){
            this.$store.commit("storage/SET_SELECTED",ActiveSelected);
            this.properties(ActiveSelected)
            this.hideContextMenu()
          }else{
            this.$store.commit("storage/SET_SELECTED",ActiveSelected);
          }
          if(this.selectedField.length == this.listings.length){
            this.allchecked = true;
          }else{
            this.allchecked = false;
          }
        }
      },
      ctrlClick(item){
        if(this.canAccess){
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : v._id == item._id ? !item.checked : v.checked 
            }
          })
          this.$store.commit("storage/SET_SELECTED",item);
        }
      },
      righClick(item){
        if(this.canAccess){
          this.item = item;
          this.listings = this.listings.map((v)=>{
            return {
              ...v,
              checked : v._id == item._id ? true : false 
            }
          })
          this.$store.commit("storage/SET_ONE_SELECTED",item)
          this.counter = 0
          this.properties(item)
          // this.$store.commit("storage/SET_ALL_SELECTED",[]);
          // this.listings = this.listings.map((v)=>{
          //   return {
          //     ...v,
          //     checked : false 
          //   }
          // })
          var menu = document.getElementById("contextMenu");
          var field = document.getElementById(item._id+"item");
          menu.style.display = 'block';
          menu.style.right = null;
          menu.style.left = field.offsetLeft+field.offsetWidth-60+"px";
          menu.style.bottom = null;
          menu.style.top = field.offsetTop + "px";
          

        }
      },
      hideContextMenu(){
        var menu = document.getElementById("contextMenu");
        menu.style.display = 'none';
        menu.style.top = 0;
      }
  }
};
</script>

<style scoped>
.tooltip .tooltip-inner{
  background: #000000 !important;
  color: #fff !important;
}
.nested-dd-home ul{
  transform: translate3d(-173px, 0px, 0px) !important;
}
.margin-12px{
    margin-left: 12px;
}
.margin-top-dd-bn{
    margin-top: 2px;
}
.text-dd-v3{
  color: #262A2C !important;
  font-size: 16px !important;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.32px;
  font-family: 'Plus Jakarta Sans';
}
</style>

<template>
  <Layout ref="layout">
    <!-- start page title -->
    <!-- <div class="row"> -->
    <belowNav v-if="(state == 'storage' && isSuspend == false) || (state == 'workgroup' && isWorkgroupSuspend == false)"/>
    <Encrypt :item="this.item"/>
    <Decrypt :item="this.item"/>
    <ModalRename :item="this.item"/>
    <ModalSendToChat :item="this.item"/>
    <ModalFolderShare/>
    <ModalShare/>
    <ModalShareWorkgroup/>
    <Move/>
    <Copy/>
    <CopyPersonal/>
    <CopyWorkgroup/>
    <Share/>
    <Download/>
    <ImageView/>
    <VideoView/>
    <AudioView/>
    <toTrash/>
    <div v-if="(state == 'storage' && isSuspend == false) || (state == 'workgroup' && isWorkgroupSuspend == false)" class="col-12 all-pages">
      <div class="page-title-box d-flex align-items-center justify-content-between" v-if="!grid">
          <table class="table table-storage default-text-style " sticky-header>
              <thead class="sticky-table-head">
                <th width="2%">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-form-checkbox
                      v-if="state == 'storage' || selectedWorkgroup.type != 'PRIVATE'"
                      id="checkbox-1"
                      name="checkbox-1"
                      :class="allchecked == true ? 'more-btn-op-1' :'more-btn'"
                      :value="true"
                      v-model="allchecked"
                      :unchecked-value="false"
                      @change="changeChecked()"
                    >
                    </b-form-checkbox>  

                  </div>
                </th>
                  <th width="55%" class="default-text-style name-column" @click="sorting()">
                      <div class="d-flex">
                        <!-- <b-form-checkbox
                          id="checkbox-1"
                          name="checkbox-1"
                          class="more-btn"
                          :value="true"
                          v-model="allchecked"
                          :unchecked-value="false"
                          @change="changeChecked()"
                        >
                        </b-form-checkbox>   -->
                        <span class="text-muted">Name </span>
                        <img v-if="sort == 'ASC'" src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1 rotate-180">
                        <img v-else src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1">
                      </div>
                  </th>
                  <!-- <th width="14%" class="default-text-style table-web-display"><span class="text-muted"></span></th> -->
                  <th width="23%" class="default-text-style table-web-display"><span class="text-muted">Last modified</span></th>
                  <th width="20%" class="default-text-style table-web-display"><span class="text-muted">File size</span></th>
                  <th width="2%" class="text-center"></th>
              </thead>
              <Sekeleton v-if="loading"/>
              <tbody v-if="listings.length > 0 && !loading && state != 'storage' && selectedWorkgroup.type == 'PRIVATE'">
                  <!-- {{listings}} -->
                  <!-- {{ state }} -->
                  <tr class="item-table" v-for="(list,index) in listings" :key="index" :id="index+'item'" :class="list.checked ? 'active' : ''"
                    v-on:click.ctrl="list.isLock == false ? ctrlClick(list) : ''" 
                    @drop="dropFile($event,index+'item',list)" 
                    @dragleave="fileDragOut($event,index+'item',list)"
                      @dragover="fileDragIn($event,index+'item',list)"
                      >
                      <!-- <pre>{{list}}</pre> -->
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'">
                        <b-form-checkbox
                        v-if="!list.isLock"
                          :id="index+'checkbox'"
                          :name="index+'checkbox'"
                          :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                          :value="true"
                          v-model="list.checked"
                          :unchecked-value="false"
                          @change="checked(list)"
                        >
                        </b-form-checkbox> 
                      </td>
                      <td v-else>
                        <b-form-checkbox
                          :id="index+'checkbox'"
                          :name="index+'checkbox'"
                          :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                          :value="true"
                          v-model="list.checked"
                          :unchecked-value="false"
                          @change="checked(list)"
                        >
                        </b-form-checkbox> 
                      </td>
                      <td class="align-items-center name-column"
                        v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'"
                        @click.exact="list.isLock == false ? clickers(list) : ''"
                      >
                          <div class="td-file" >
                            <div class="d-flex align-items-center py-2">
                              <!-- <b-form-checkbox
                                :id="index+'checkbox'"
                                :name="index+'checkbox'"
                                class="more-btn"
                                :value="true"
                                v-model="list.checked"
                                :unchecked-value="false"
                                @change="checked(list)"
                              >
                              </b-form-checkbox>   -->
                              <div class="d-flex ">
                                <div class="file-name">
                                  <!-- {{limit}} {{total}} -->
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                  <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                  <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px" :class="list.isLock ? 'change-opacity' : ''"/>
                                  <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name"  class="mx-2" :class="list.isLock ? 'change-opacity' : ''">{{list.file_name}}</span>
                                  <b-form v-else >
                                      <b-form-group id="input-group-1" class="form-group-rename"   label-class="label-input" label-for="input-1">
                                          <b-input-group>
                                              <!-- <b-input-group-append>
                                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                              </b-input-group-append> -->
                                              <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                              <b-input-group-append>
                                                  <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                  <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                              </b-input-group-append>
                                          </b-input-group>
                                      </b-form-group>
                                  </b-form>
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                </div>
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                              </div>
                            </div>
                              <div class="icon-status-file">
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                                <img v-if="list.isLinkShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="ml-3">
                                <img v-if="list.isShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="ml-3">

                              </div>
                          </div>
                      </td>
                      <td v-else class="align-items-center name-column"
                        @click.exact="clickers(list)"
                      >
                          <div class="td-file" >
                            <div class="d-flex align-items-center py-2">
                              <!-- <b-form-checkbox
                                :id="index+'checkbox'"
                                :name="index+'checkbox'"
                                class="more-btn"
                                :value="true"
                                v-model="list.checked"
                                :unchecked-value="false"
                                @change="checked(list)"
                              >
                              </b-form-checkbox>   -->
                              <div class="d-flex ">
                                <div class="file-name">
                                  <!-- {{limit}} {{total}} -->
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                  <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                  <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px"/>
                                  <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name"  class="mx-2">{{list.file_name}}</span>
                                  <b-form v-else >
                                      <b-form-group id="input-group-1" class="form-group-rename"   label-class="label-input" label-for="input-1">
                                          <b-input-group>
                                              <!-- <b-input-group-append>
                                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                              </b-input-group-append> -->
                                              <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                              <b-input-group-append>
                                                  <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                  <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                              </b-input-group-append>
                                          </b-input-group>
                                      </b-form-group>
                                  </b-form>
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                </div>
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                              </div>
                            </div>
                              <div class="icon-status-file">
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-1">
                                <img v-if="list.isLinkShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="ml-3">
                                <img v-if="list.isShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="ml-3">

                              </div>
                          </div>
                      </td>
                      <!-- <td class="table-web-display">
                        <p><span>Encrypt</span></p>
                      </td> -->
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''"  class="table-web-display">
                          <p :class="list.isLock ? 'change-opacity' : ''"> {{list.datemodified}} </p>
                      </td>
                      <td v-else @click.exact="clickers(list)"  class="table-web-display">
                          <p> {{list.datemodified}} </p>
                      </td>
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''"  class="table-web-display">
                          <p :class="list.isLock ? 'change-opacity' : ''">{{list.size}}</p>
                      </td>
                      <td v-else @click.exact="clickers(list)"  class="table-web-display">
                          <p>{{list.size}}</p>
                      </td>
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''">
                          <div v-if="canAccess && list.isLock == false && selectedField.length < 2" 
                            :class="list.checked == true && selectedField.length < 2 ? 'more-btn-op-1' : 'more-btn'"
                          >
                              <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                  <template #button-content>
                                      <img src="@/assets/images/icon/btn-more.svg" alt @click.exact="clickers(list)"/>
                                  </template>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">{{list.isFolder ? 'Open Folder' : 'Open File'}}</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6) && (isOwner || idUser == list.added_by)" @click="sendInfoEncrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Encrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6) && (isOwner || idUser == list.added_by)" @click="sendInfoDecrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Decrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="state == 'storage' ? shareopennew(list) : shareOpenNewWg(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button> -->
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && isOwner" @click="move(list)">
                                      <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Move item</span>
                                  </b-dropdown-item-button> -->
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                      <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Copy</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown dropright class="nested-dd-home" id="dropdown-3" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                    <template #button-content>
                                      <div class="d-flex justify-content-between" style="width: 156px;">
                                        <div>
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                          <span class="ml-3 text-dd-v3">Copy to</span>
                                        </div>
                                        <div class="margin-top-dd-bn ml-2">
                                          <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                        </div>
                                      </div>
                                    </template>
                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Personal</span>
                                    </b-dropdown-item-button>
                                    <!-- <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Workgroup</span>
                                    </b-dropdown-item-button> -->
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="!list.isFolder" @click="sendToChat(list)">
                                      <img src="@/assets/images/icon/fi-rr-send-to-chat.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Send to chat</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && idUser == list.added_by" @click="renameItem(list)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>   
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="rename(index)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button  @click="openDownload(list)" v-if="(isOwner) && (list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)">
                                      <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="detailToggle2(list)">
                                      <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Details</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item-button v-if="isOwner || idUser == list.added_by" @click="deleteFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style red">Move to Trash</span>
                                  </b-dropdown-item-button>
                              </b-dropdown>
                          </div>
                          <div v-else>
                            <img class="change-opcity ml-3" src="@/assets/images/icon/lock-private-wg.svg" alt="">
                          </div>
                      </td>
                      <td v-else @click.exact="clickers(list)">
                          <div v-if="canAccess" 
                            :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                          >
                              <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                  <template #button-content>
                                      <img src="@/assets/images/icon/btn-more.svg" alt @click.exact="clickers(list)"/>
                                  </template>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">{{list.isFolder ? 'Open Folder' : 'Open File'}}</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6) && (isOwner || canAccess)" @click="sendInfoEncrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Encrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6) && (isOwner || canAccess)" @click="sendInfoDecrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Decrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="state == 'storage' ? shareopennew(list) : shareOpenNewWg(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="list.isFolder && state == 'storage'" v-b-modal.modal-folder-share-v3>
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="move(list)">
                                      <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Move Item</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                      <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Copy</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown dropright class="nested-dd-home" id="dropdown-3" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                    <template #button-content>
                                      <div class="d-flex justify-content-between" style="width: 156px;">
                                        <div>
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                          <span class="ml-3 text-dd-v3">Copy to</span>
                                        </div>
                                        <div class="margin-top-dd-bn ml-2">
                                          <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                        </div>
                                      </div>
                                    </template>
                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Personal</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Workgroup</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="renameItem(list)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>   
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="rename(index)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="openDownload(list)" >
                                      <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="detailToggle2(list)">
                                      <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Details</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item-button v-if="isOwner || idUser == list.added_by" @click="deleteFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style red">Move to Trash</span>
                                  </b-dropdown-item-button>
                              </b-dropdown>
                          </div>
                      </td>
                  </tr>
                  <!-- <tr v-if="limit < total">
                    <td colspan="4">
                      <div class="d-flex justify-content-center" >
                          <button class="load-more-items" @click="loadmore()"> <i v-if="loading"></i>Load More</button>
                      </div>
                    </td>
                  </tr> -->
              </tbody>
              <tbody v-else-if="listings.length > 0 && !loading">
                  <!-- <pre>{{listings}}</pre> -->
                  <!-- {{ state }} -->
                  <tr class="item-table" v-for="(list,index) in listings" :key="index" :id="index+'item'" :class="list.checked ? 'active' : ''"
                    v-on:click.ctrl="ctrlClick(list)" 
                    @drop="dropFile($event,index+'item',list)" 
                    @dragleave="fileDragOut($event,index+'item',list)"
                      @dragover="fileDragIn($event,index+'item',list)"
                      >
                      <!-- <pre>{{list.locked}}</pre> -->
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'">
                        <b-form-checkbox
                        v-if="!list.isLock"
                          :id="index+'checkbox'"
                          :name="index+'checkbox'"
                          :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                          :value="true"
                          v-model="list.checked"
                          :unchecked-value="false"
                          @change="checked(list)"
                        >
                        </b-form-checkbox> 
                      </td>
                      <td v-else>
                        <b-form-checkbox
                          :id="index+'checkbox'"
                          :name="index+'checkbox'"
                          :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                          :value="true"
                          v-model="list.checked"
                          :unchecked-value="false"
                          @change="checked(list)"
                        >
                        </b-form-checkbox> 
                      </td>
                      <td class="align-items-center name-column"
                        v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'"
                        @click.exact="list.isLock == false ? clickers(list) : ''"
                      >
                          <div class="td-file" >
                            <div class="d-flex align-items-center py-2">
                              <!-- <b-form-checkbox
                                :id="index+'checkbox'"
                                :name="index+'checkbox'"
                                class="more-btn"
                                :value="true"
                                v-model="list.checked"
                                :unchecked-value="false"
                                @change="checked(list)"
                              >
                              </b-form-checkbox>   -->
                              <div class="d-flex ">
                                <div class="file-name">
                                  <!-- {{limit}} {{total}} -->
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                  <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                  <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px" :class="list.isLock ? 'change-opacity' : ''"/>
                                  <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name"  class="mx-2" :class="list.isLock ? 'change-opacity' : ''">{{list.file_name}}</span>
                                  <b-form v-else >
                                      <b-form-group id="input-group-1" class="form-group-rename"   label-class="label-input" label-for="input-1">
                                          <b-input-group>
                                              <!-- <b-input-group-append>
                                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                              </b-input-group-append> -->
                                              <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                              <b-input-group-append>
                                                  <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                  <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                              </b-input-group-append>
                                          </b-input-group>
                                      </b-form-group>
                                  </b-form>
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                </div>
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                              </div>
                            </div>
                              <div class="icon-status-file">
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                                <img v-if="list.isLinkShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="ml-3">
                                <img v-if="list.isShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="ml-3">

                              </div>
                          </div>
                      </td>
                      <td v-else class="align-items-center name-column"
                        @click.exact="clickers(list)"
                      >
                          <div class="td-file" >
                            <div class="d-flex align-items-center py-2">
                              <!-- <b-form-checkbox
                                :id="index+'checkbox'"
                                :name="index+'checkbox'"
                                class="more-btn"
                                :value="true"
                                v-model="list.checked"
                                :unchecked-value="false"
                                @change="checked(list)"
                              >
                              </b-form-checkbox>   -->
                              <div class="d-flex ">
                                <div class="file-name">
                                  <!-- {{limit}} {{total}} -->
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                  <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/lock-encrypt.svg`)" alt height="24px"/>
                                  <img v-else :src="require(`@/assets/images/icon/${list.icon}`)" alt height="24px"/>
                                  <span v-if="!list.isEdit"  v-b-tooltip.hover.ds1000.top="list.file_name"  class="mx-2" :class="list.locked == true ? 'change-opacity' : ''">{{list.file_name}}</span>
                                  <b-form v-else >
                                      <b-form-group id="input-group-1" class="form-group-rename"   label-class="label-input" label-for="input-1">
                                          <b-input-group>
                                              <!-- <b-input-group-append>
                                                  <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                              </b-input-group-append> -->
                                              <b-form-input id="input-1" autofocus class="login-input-style" v-model="valueRename" type="text" placeholder="Folder New"></b-form-input>
                                              <b-input-group-append>
                                                  <b-button @click="submitRename(index)" class="btn-on-input" v-b-tooltip.hover title="Save" ><i class="dripicons-checkmark text-success"></i></b-button>
                                                  <b-button @click="rename(index)" class="btn-on-input" v-b-tooltip.hover title="Cancel" ><i class="dripicons-cross text-danger"></i></b-button>
                                              </b-input-group-append>
                                          </b-input-group>
                                      </b-form-group>
                                  </b-form>
                                  <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt data-toggle="tooltip" title="This file is shared"> -->
                                </div>
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-icon.svg`)" alt> -->
                                <!-- <span v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4" class="ml-3 encrypt-text">Encrypted</span> -->
                              </div>
                            </div>
                              <div class="icon-status-file">
                                <!-- <img v-if="list.isShare" :src="require(`@/assets/images/icon/shared-file.svg`)" alt data-toggle="tooltip" title="This file is shared" class="ml-2"> -->
                                <!-- <img v-if="list.encrypt == 2 || list.encrypt == 7" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt data-toggle="tooltip" title="This file is encrypted" class="ml-2"> -->
                                <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                                <img v-if="list.isLinkShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="ml-3">
                                <img v-if="list.isShare && list.isFolder != true" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="ml-3"> 
                              </div>
                          </div>
                      </td>
                      <!-- <td class="table-web-display">
                        <p><span>Encrypt</span></p>
                      </td> -->
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''"  class="table-web-display">
                          <p :class="list.isLock ? 'change-opacity' : ''"> {{list.datemodified}} </p>
                      </td>
                      <td v-else @click.exact="clickers(list)"  class="table-web-display">
                          <p :class="list.locked == true ? 'change-opacity' : ''"> {{list.datemodified}} </p>
                      </td>
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''"  class="table-web-display">
                          <p :class="list.isLock ? 'change-opacity' : ''">{{list.size}}</p>
                      </td>
                      <td v-else @click.exact="clickers(list)"  class="table-web-display">
                          <p :class="list.locked == true ? 'change-opacity' : ''">{{list.size}}</p>
                      </td>
                      <td v-if="state != 'storage' && selectedWorkgroup.type == 'PRIVATE'" @click.exact="list.isLock == false ? clickers(list) : ''">
                          <div v-if="canAccess && list.isLock == false && selectedField.length < 2" 
                            :class="list.checked == true && selectedField.length < 2 ? 'more-btn-op-1' : 'more-btn'"
                          >
                              <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                  <template #button-content>
                                      <img src="@/assets/images/icon/btn-more.svg" alt @click.exact="clickers(list)"/>
                                  </template>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">{{list.isFolder ? 'Open Folder' : 'Open File'}}</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && isOwner" @click="sendInfoEncrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Encrypt</span>
                                  </b-dropdown-item-button> -->
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7) && isOwner" @click="sendInfoDecrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Decrypt</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="state == 'storage' ? shareopennew(list) : shareOpenNewWg(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button> -->
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4) && isOwner" @click="move(list)">
                                      <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Move item</span>
                                  </b-dropdown-item-button> -->
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                      <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Copy</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown dropright class="nested-dd-home" id="dropdown-3" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                    <template #button-content>
                                      <div class="d-flex justify-content-between" style="width: 156px;">
                                        <div>
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                          <span class="ml-3 text-dd-v3">Copy to</span>
                                        </div>
                                        <div class="margin-top-dd-bn ml-2">
                                          <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                        </div>
                                      </div>
                                    </template>
                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Personal</span>
                                    </b-dropdown-item-button>
                                    <!-- <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Workgroup</span>
                                    </b-dropdown-item-button> -->
                                  </b-dropdown>
                                  <!-- <b-dropdown-item-button v-if="!list.isFolder" @click="sendToChat(list)">
                                      <img src="@/assets/images/icon/fi-rr-send-to-chat.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Send to chat</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="renameItem(list)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>   
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="rename(index)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button  @click="openDownload(list)" v-if="(isOwner) && (list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)">
                                      <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="detailToggle2(list)">
                                      <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Details</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item-button v-if="isOwner || idUser == list.added_by" @click="deleteFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style red">Move to Trash</span>
                                  </b-dropdown-item-button>
                              </b-dropdown>
                          </div>
                          <div v-else>
                            <img class="change-opcity ml-3" src="@/assets/images/icon/lock-private-wg.svg" alt="">
                          </div>
                      </td>
                      <td v-else @click.exact="clickers(list)">
                          <div v-if="canAccess && list.locked != true && selectedField.length < 2" 
                            :class="list.checked == true && selectedField.length < 2 ? 'more-btn-op-1' : 'more-btn'"
                          >
                              <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu">
                                  <template #button-content>
                                      <img src="@/assets/images/icon/btn-more.svg" alt @click.exact="clickers(list)"/>
                                  </template>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">{{list.isFolder ? 'Open Folder' : 'Open File'}}</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6) && (isOwner || canAccess)" @click="sendInfoEncrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Encrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6) && (isOwner || canAccess)" @click="sendInfoDecrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Decrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="state == 'storage' ? shareopennew(list) : shareOpenNewWg(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="list.isFolder && state == 'storage'" v-b-modal.modal-folder-share-v3>
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="move(list)">
                                      <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Move Item</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                      <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Copy</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown dropright class="nested-dd-home" id="dropdown-3" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                    <template #button-content>
                                      <div class="d-flex justify-content-between" style="width: 156px;">
                                        <div>
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                          <span class="ml-3 text-dd-v3">Copy to</span>
                                        </div>
                                        <div class="margin-top-dd-bn ml-2">
                                          <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                        </div>
                                      </div>
                                    </template>
                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Personal</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Workgroup</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="!list.isFolder && state == 'workgroup'" @click="sendToChat(list)">
                                      <img src="@/assets/images/icon/fi-rr-send-to-chat.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Send to chat</span>
                                  </b-dropdown-item-button> 
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="renameItem(list)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>   
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="rename(index)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button  @click="openDownload(list)" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)">
                                      <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="detailToggle2(list)">
                                      <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Details</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-divider></b-dropdown-divider>
                                  <b-dropdown-item-button v-if="isOwner || idUser == list.added_by" @click="deleteFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style red">Move to Trash</span>
                                  </b-dropdown-item-button>
                              </b-dropdown>
                          </div>
                          <div v-else-if="list.locked == true">
                            <i class="bx bx-loader d-flex justify-content-center align-items-center font-size-16 bx-tada"></i>
                          </div>
                      </td>
                  </tr>
                  <!-- <tr v-if="limit < total">
                    <td colspan="4">
                      <div class="d-flex justify-content-center" >
                          <button class="load-more-items" @click="loadmore()"> <i v-if="loading"></i>Load More</button>
                      </div>
                    </td>
                  </tr> -->
              </tbody>
          </table>
      </div>
      <div class="page-title-box d-flex justify-content-start align-items-start grid-view" id="container-file" v-else>
        <table class="sticky-table-head table table-storage default-text-style " sticky-header>
          <thead>
              <th width="64%" class="default-text-style" @click="sorting()" colspan="3">
                  <div class="d-flex">
                    <b-form-checkbox
                      id="checkbox-1"
                      name="checkbox-1"
                      :class="allchecked == true ? 'more-btn-op-1' :'more-btn'"
                      :value="true"
                      v-model="allchecked"
                      :unchecked-value="false"
                      @change="changeChecked()"
                    >
                    </b-form-checkbox>  
                    <span class="text-muted">Name </span>
                    <img v-if="sort == 'ASC'" src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1 rotate-180">
                    <img v-else src="@/assets/images/icon/Chevron-Down.svg" alt="" class="ml-1">
                  </div>
              </th>
              <th width="5%"></th>
          </thead>
        </table>
        <div class="d-flex justify-content-start align-items-start grid-view" v-if="loading">
          <div class="card dip-card-item m-2" v-for="loop in 18" :key="loop">
            <div class="card-body d-flex flex-column justify-content-center align-items-center">
              <div class="mt-4"></div>
              <b-skeleton-img card-img="left" width="72px" height="72px"></b-skeleton-img>
              <div class="mt-2"></div>
              <b-skeleton width="100px"></b-skeleton>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-start grid-view"  v-if="listings.length > 0 && !loading && state != 'storage' && selectedWorkgroup.type == 'PRIVATE'">
          <div  :class="`card dip-card-item m-2 ${list.checked ? 'active' : ''}`" 
              v-on:click.ctrl="list.isLock == false ? ctrlClick(list) : ''" v-for="(list,index) in listings" 
              :key="index" 
              :id="list._id+'item'" 
              style="height: 164px; width: 168px"
              @contextmenu.prevent="list.isLock == false ? righClick(list) : ''"
              v-on:click.exact="hideContextMenu"  
              v-click-outside="hideContextMenu"
              @drop="dropFile($event,index+'item',list)" 
              @dragleave="fileDragOut($event,index+'item',list)"
                @dragover="fileDragIn($event,index+'item',list)"
              >
            <div class="card-body">
              <b-form-checkbox
                v-if="list.isLock == false"
                :id="index+'checkbox'"
                :name="index+'checkbox'"
                :value="true"
                class="checkboxhover"
                v-model="list.checked"
                :unchecked-value="false"
                @change="checked(list)"
              >
              </b-form-checkbox> 
              <div v-else class="mt-2-9">
                <div class="d-flex justify-content-between">
                  <span></span>
                  <img class="change-opacity" src="@/assets/images/icon/lock-private-wg.svg" alt="">
                </div>
              </div>
              <div :id="index+'card'" class="d-flex flex-column justify-content-center align-items-center">
                <div class="mb-1" @click.exact ="list.isLock == false ? clickers(list) : ''">
                  <img
                    v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6"
                    class="lock-md mb-md"
                    :class="list.isLock == true ? 'change-opacity' : ''"
                    src="@/assets/images/icon/lock-encrypt.svg"
                    alt
                  />
                  <img
                    v-else
                    class="avatar-md mb-md"
                    :class="list.isLock == true ? 'change-opacity' : ''"
                    :src="require(`@/assets/images/icon/${list.icon}`)"
                    alt
                  />
                </div>
                <!-- <h5 class="default-text-style elipse-text">
                  <a
                    href="javascript: void(0);"
                    class="text-dark "
                    >{{ list.file_name }}</a
                  >
                </h5> -->
                <h5 class="default-text-style elipse-text" @click.exact ="list.isLock == false ? clickers(list) : ''">
                  <div class="d-flex">
                    <div class="elipse-text" :class="list.isLock == true ? 'change-opacity' : ''">
                      <a
                        href="javascript: void(0);"
                        class="text-dark"
                        >{{ list.file_name }}</a
                      >
                    </div>
                    <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="ml-2">
                    <img v-if="list.isLinkShare && list.isFolder != true && state == 'storage'" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="ml-3">
                    <img v-if="list.isShare && list.isFolder != true && state == 'storage'" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="ml-3">
                    <!-- <p v-if="list.encrypt == 2 || list.encrypt == 7" class=" ml-1 encrypt-text">
                        Encrypted
                      </p> -->

                  </div>
                </h5>
              </div>
              <b-tooltip :target="index+'card'" placement="top" :delay="{show:800,hide:100}">
                {{ list.file_name }}
              </b-tooltip>
            </div>
          </div> 
        </div>
        <div class="d-flex justify-content-start align-items-start grid-view"  v-else-if="listings.length > 0 && !loading">
          <div  :class="`card dip-card-item m-2 ${list.checked ? 'active' : ''}`" 
              v-on:click.ctrl="list.locked == false ? ctrlClick(list) : ''" v-for="(list,index) in listings" 
              :key="index" 
              :id="list._id+'item'" 
              style="height: 164px; width: 168px"
              >
          <!-- <div  :class="`card dip-card-item m-2 ${list.checked ? 'active' : ''}`" 
              v-on:click.ctrl="list.locked == false ? ctrlClick(list) : ''" v-for="(list,index) in listings" 
              :key="index" 
              :id="list._id+'item'" 
              style="height: 164px; width: 168px"
              @contextmenu.prevent="list.locked == false ? righClick(list) : ''"
              v-on:click.exact="hideContextMenu"  
              @dblclick="(counter += 1), list.locked == false ? doubleClick(list) : ''"
              v-click-outside="hideContextMenu"
              @drop="dropFile($event,index+'item',list)" 
              @dragleave="fileDragOut($event,index+'item',list)"
                @dragover="fileDragIn($event,index+'item',list)"
              > -->
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <b-form-checkbox
                  :id="index+'checkbox'"
                  :name="index+'checkbox'"
                  :value="true"
                  class="checkboxhover"
                  :class="list.checked == true ? 'more-btn-op-1' : 'more-btn'"
                  v-model="list.checked"
                  :unchecked-value="false"
                  @change="checked(list)"
                >
                </b-form-checkbox>
                <div v-if="list.locked">
                  <i class="bx bx-loader d-flex justify-content-center align-items-center font-size-16 bx-tada"></i>
                </div>
                <div :class="getClassStyle(list)" v-else>

                  <b-dropdown id="dropdown-home" dropright class="bg-transparent more-menu grid-more-padding">
                                  <template #button-content>
                                      <img src="@/assets/images/icon/Icons-Ellipsis-Vertical.svg" alt @click.exact="clickers(list)"/>
                                  </template>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2) && (list.encrypt != 7)" @click="openFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">{{list.isFolder ? 'Open Folder' : 'Open File'}}</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6) && (isOwner || canAccess)" @click="sendInfoEncrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Encrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6) && (isOwner || canAccess)" @click="sendInfoDecrypt(list)">
                                      <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
                                      <span class="ml-3 default-text-style">Decrypt</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)" @click="state == 'storage' ? shareopennew(list) : shareOpenNewWg(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button v-if="list.isFolder && state == 'storage'" v-b-modal.modal-folder-share-v3>
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(!list.isFolder && list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="shareopen(list)">
                                      <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Share</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="move(list)">
                                      <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Move Item</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="copy(list)" >
                                      <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Copy</span>
                                  </b-dropdown-item-button> -->
                                  <b-dropdown dropright class="nested-dd-home" id="dropdown-3" v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)">
                                    <template #button-content>
                                      <div class="d-flex justify-content-between" style="width: 164px; padding: 8px 8px 8px 5px;">
                                        <div>
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class="margin-12px"/>
                                          <span class="ml-3 text-dd-v3">Copy to</span>
                                        </div>
                                        <div class="margin-top-dd-bn ml-2">
                                          <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                                        </div>
                                      </div>
                                    </template>
                                    <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Personal</span>
                                    </b-dropdown-item-button>
                                    <b-dropdown-item-button class="copy-file-v3" @click="state == 'workgroup' ? copy(list) : copyWorkgroup(list)">
                                          <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                                          <span class="ml-3 default-text-style">Workgroup</span>
                                    </b-dropdown-item-button>
                                  </b-dropdown>
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="renameItem(list)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>   
                                  <!-- <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4)" @click="rename(index)">
                                      <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Rename</span>
                                  </b-dropdown-item-button>    -->
                                  <b-dropdown-item-button v-if="(list.encrypt != 2 && list.encrypt != 7 && list.encrypt != 4 && list.encrypt != 6)"  @click="openDownload(list)" >
                                      <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Download</span>
                                  </b-dropdown-item-button>
                                  <b-dropdown-item-button @click="detailToggle2(list)">
                                      <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style">Details</span>
                                  </b-dropdown-item-button>
                                  <!-- <b-dropdown-divider></b-dropdown-divider> -->
                                  <b-dropdown-item-button v-if="isOwner || idUser == list.added_by" @click="deleteFile(list)">
                                      <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
                                      <span class="ml-3  default-text-style red">Move to Trash</span>
                                  </b-dropdown-item-button>
                              </b-dropdown>
                </div>





              </div>
              <div :id="index+'card'" class="d-flex flex-column justify-content-center align-items-center">
                <div class="mb-1" @click.exact="clickers(list)">
                  <img
                    v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 6 || list.encrypt == 4"
                    class="lock-md mb-md"
                    :class="list.locked == true ? 'change-opacity' : ''"
                    src="@/assets/images/icon/lock-encrypt.svg"
                    alt
                  />
                  <img
                    v-else
                    class="avatar-md mb-md"
                    :class="list.locked == true ? 'change-opacity' : ''"
                    :src="require(`@/assets/images/icon/${list.icon}`)"
                    alt
                  />
                </div>
                <!-- <h5 class="default-text-style elipse-text">
                  <a
                    href="javascript: void(0);"
                    class="text-dark "
                    >{{ list.file_name }}</a
                  >
                </h5> -->
                <h5 class="default-text-style elipse-text" @click.exact ="clickers(list)">
                  <div class="d-flex">
                    <div class="elipse-text">
                      <a
                        href="javascript: void(0);"
                        class="text-dark "
                        :class="list.locked == true ? 'change-opacity' : ''"
                        >{{ list.file_name }}</a
                      >
                    </div>
                    <img v-if="list.encrypt == 2 || list.encrypt == 7 || list.encrypt == 4 || list.encrypt == 6" :class="list.locked == true ? 'change-opacity' : ''" :src="require(`@/assets/images/icon/encrypted-file.svg`)" alt v-b-tooltip.hover title="This file is encrypted" class="m-4px">
                    <img v-if="list.isLinkShare && list.isFolder != true && state == 'storage'" :class="list.locked == true ? 'change-opacity' : ''" :src="require(`@/assets/images/icon/shared-file-link.svg`)" alt v-b-tooltip.hover title="Shared by link" class="m-4px">
                    <img v-if="list.isShare && list.isFolder != true && state == 'storage'" :class="list.locked == true ? 'change-opacity' : ''" :src="require(`@/assets/images/icon/shared-file.svg`)" alt v-b-tooltip.hover title="Shared by email" class="m-4px"> 
                    <!-- <p v-if="list.encrypt == 2 || list.encrypt == 7" class=" ml-1 encrypt-text">
                        Encrypted
                      </p> -->

                  </div>
                </h5>
              </div>
              <b-tooltip :target="index+'card'" placement="top" :delay="{show:800,hide:100}">
                {{ list.file_name }}
              </b-tooltip>
            </div>
          </div> 
        </div>
        
        <b-list-group id="contextMenu">
          <b-list-group-item v-if="item.encrypt != 2 && item.encrypt != 7" button @click="openFile(null)">
            <img src="@/assets/images/icon/fi-rr-expand.svg" alt height="100%" />
            <span class="ml-3 default-text-style">{{item.isFolder ? 'Open Folder' : 'Open File'}}</span>
          </b-list-group-item>
          <b-list-group-item v-if="!item.isFolder && item.encrypt != 2 && item.encrypt != 7 && (isOwner || canAccess) && state == 'workgroup'" v-b-modal.modal-encrypt>
          <!-- <b-list-group-item v-if="!item.isFolder && item.encrypt != 2 && item.encrypt != 7 && isOwner && state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" v-b-modal.modal-encrypt> -->
              <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Encrypt</span>
          </b-list-group-item>
          <b-list-group-item v-else-if="!item.isFolder && item.encrypt != 2 && item.encrypt != 7 && isOwner && state == 'storage'" v-b-modal.modal-encrypt>
              <img src="@/assets/images/icon/fi-rr-shield-check.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Encrypt</span>
          </b-list-group-item>
          <b-list-group-item v-if="(!item.isFolder && item.encrypt == 2 || item.encrypt == 7) && (isOwner || canAccess) && state == 'workgroup'" v-b-modal.modal-decrypt>
              <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Decrypt</span>
          </b-list-group-item>
          <b-list-group-item v-if="(!item.isFolder && item.encrypt == 2 || item.encrypt == 7) && isOwner && state == 'storage'" v-b-modal.modal-decrypt>
              <img src="@/assets/images/icon/fi-rr-shield-cross.svg" alt height="100%" />
              <span class="ml-3 default-text-style">Decrypt</span>
          </b-list-group-item>
          <b-list-group-item button @click="state == 'storage' ? shareopennew(null) : shareOpenNewWg(null)" v-if="!item.isFolder">
            <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Share</span>
          </b-list-group-item>
          <b-list-group-item v-if="item.isFolder && this.state != 'workgroup'" button @click="sharefolder(null)">
            <img src="@/assets/images/icon/fi-rr-redo.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Share</span>
          </b-list-group-item>
          <b-list-group-item v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" button @click="move(null)">
            <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Move Item</span>
          </b-list-group-item>
          <b-list-group-item v-else-if="state == 'storage'" button @click="move(null)">
            <img src="@/assets/images/icon/fi-rr-flag.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Move</span>
          </b-list-group-item>
          <!-- <b-list-group-item button @click="copy(null)">
            <img src="@/assets/images/icon/fi-rr-copy-alt.png" alt height="100%" />
            <span class="ml-3  default-text-style">Copy</span>
          </b-list-group-item> -->
          <!-- <b-list-group-item> -->
            <b-dropdown dropright class="nested-dd-home" id="dropdown-4" v-if="(item.encrypt != 2 && item.encrypt != 7 && item.encrypt != 4)">
              <template #button-content>
                <div class="d-flex justify-content-between">
                  <div>
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="75%" class=""/>
                    <span class="ml-3 text-dd-v3">Copy to</span>
                  </div>
                  <div class="margin-top-dd-bn ml-2">
                    <img src="@/assets/images/icon/chevLeft.svg" alt="" class="">
                  </div>
                </div>
              </template>
              <b-dropdown-item-button class="copy-file-v3" @click="copyPersonal(null)">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                    <span class="ml-3 default-text-style">Personal</span>
              </b-dropdown-item-button>
              <b-dropdown-item-button v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" class="copy-file-v3" @click="state == 'workgroup' ? copy(null) : copyWorkgroup(null)">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                    <span class="ml-3 default-text-style">Workgroup</span>
              </b-dropdown-item-button>
              <b-dropdown-item-button v-else-if="state == 'storage'" class="copy-file-v3" @click="state == 'workgroup' ? copy(null) : copyWorkgroup(null)">
                    <img src="@/assets/images/icon/fi-rr-copy-alt.svg" alt height="100%" />
                    <span class="ml-3 default-text-style">Workgroup</span>
              </b-dropdown-item-button>
            </b-dropdown>
          <!-- </b-list-group-item> -->
          <b-list-group-item button v-if="!item.isFolder && state == 'workgroup' && selectedWorkgroup.type == 'PRIVATE' && (item.added_by == idUser || isOwner)" @click="sendToChat(null)">
            <img src="@/assets/images/icon/fi-rr-send-to-chat.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Send to chat</span>
          </b-list-group-item>
          <b-list-group-item button v-if="!item.isFolder && state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE' && canAccess" @click="sendToChat(null)">
            <img src="@/assets/images/icon/fi-rr-send-to-chat.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Send to chat</span>
          </b-list-group-item>
          <b-list-group-item button v-if="item.encrypt != 2 && item.encrypt != 7 && state == 'workgroup' && selectedWorkgroup.type == 'PRIVATE' && item.added_by == idUser" @click="renameItem(null)">
            <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Rename</span>
          </b-list-group-item>
          <b-list-group-item button v-else-if="item.encrypt != 2 && item.encrypt != 7 && state == 'storage'" @click="renameItem(null)">
            <img src="@/assets/images/icon/fi-rr-edit.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Rename</span>
          </b-list-group-item>
          <b-list-group-item v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE' && (item.encrypt != 2 && item.encrypt != 7 && item.encrypt != 4 && item.encrypt != 6)" button @click="openDownload(null)">
            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Download</span>
          </b-list-group-item>
          <b-list-group-item v-else-if="state == 'storage' && (item.encrypt != 2 && item.encrypt != 7 && item.encrypt != 4 && item.encrypt != 6)" button @click="openDownload(null)">
            <img src="@/assets/images/icon/fi-rr-download.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Download</span>
          </b-list-group-item>
          <b-list-group-item button @click="detailToggle2(null)">
            <img src="@/assets/images/icon/fi-rr-info-2.svg" alt height="100%" />
            <span class="ml-3  default-text-style">Details</span>
          </b-list-group-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-list-group-item button v-if="isOwner || idUser == item.added_by" @click="deleteFile(null)">
            <img src="@/assets/images/icon/fi-rr-trash-2.svg" alt height="100%" />
            <span class="ml-3  default-text-style red">Move to Trash</span>
          </b-list-group-item>
        </b-list-group>
      </div>
      <div v-if="listings.length <= 0 && !loading && keyword == ''" class="d-flex flex-column justify-content-center align-items-center">
          <!-- <span class="mt-2"></span><br> -->
          <img src="@/assets/images/dip-empty-file.svg" alt class="img-fit mb-2" />
          <p class="mb-0 pb-0 title-empty mb-2">Keep your files organized</p>
          <p class="text-empty pt-0 mt-0">Upload files from your computer or create a new folder to start organizing.</p>
          <div v-if="canAccess" class="d-flex">
            <div class="container-upload-create pointers" @click="uploadFile()">
              <img src="@/assets/images/icon/Etc.svg" alt="" class="mr-2">
              <p>Upload File</p>
            </div>
            <div v-if="state == 'workgroup' && selectedWorkgroup.type != 'PRIVATE'" class="container-upload-create cursor-pointer" @click="createFolder()">
              <img src="@/assets/images/icon/folderPlus.svg" alt="" class="mr-2">
              <p>Create Folder</p>
            </div>
            <div v-else-if="state == 'storage'" class="container-upload-create cursor-pointer" @click="createFolder()">
              <img src="@/assets/images/icon/folderPlus.svg" alt="" class="mr-2">
              <p>Create Folder</p>
            </div>
          </div>
      </div>
      <div v-if="listings.length <= 0 && !loading && keyword != ''" class="d-flex flex-column justify-content-center align-items-center">
          <!-- <span class="mt-2"></span><br> -->
          <img src="@/assets/images/new-files-not-found.svg" alt class="img-fit mb-2" />
          <p class="text-empty pt-0 my-0">We can’t find what you’re looking for.</p>
          <p class="text-empty pt-0 my-0">Check your spelling or try more general words.</p>
      </div>
    </div>

    <SuspendPage v-if="isSuspend == true && state == 'storage'" />

    <SuspendWorkgroupPage v-if="isWorkgroupSuspend == true && state == 'workgroup'"/>
      
    <div id="drag-modal">
      <div class="p-4">
        <p class="default-text-style">
          <span class="text-white">Drop files to instantly upload them to:</span>
        </p>
        <div class="d-flex justify-content-center align-items-center">
          <img :src="require(`@/assets/images/icon/Folder_white.png`)" alt height="24px"/>
          <p class="default-text-style p-0 my-0 mx-2">
            <span class="text-white">{{this.folderToUpload != null ? this.folderToUpload.file_name : ""}}</span>
          </p>
        </div>
      </div>
    </div>


    <!-- </div> -->
    <!-- end page title -->
  </Layout>
</template>

<style>
.change-opacity{
  opacity: 0.6;
}
.mt-2-9{
  margin-top: 2.9px;
}
</style>