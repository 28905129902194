<script>
export default {
    props: {
        noclear: {
            type: String,
            required: false,
            default: "",
        }
    },
    data() {
        return {
        };
    },
    computed: {
        items() {
            return this.$store.getters['storage/breadcumbs'].slice(-2)
        },
        onDropDown(){
            return this.$store.getters['storage/breadcumbs'].slice(0,-2);
        },
        state(){
            // return this.$store.getters['state/state'];
            return 'storage'
        }
    },
    methods:{
        openFolder(){
            this.$store.commit(this.state+"/SET_KEYWORD","");
            this.$store.commit(this.state+"/SET_PARENT_ID","");
            this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
            this.$store.dispatch(this.state+"/listing",this.$props.noclear)
        },
        breadClick(item){
            this.$store.commit(this.state+"/SET_KEYWORD","");
            this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
            // this.$store.commit(this.state+"/SET_BREADCUMB",item);
            this.$store.dispatch(this.state+"/listing",this.$props.noclear)
        },
        back(){
            this.$store.commit(this.state+"/SET_KEYWORD","");
            this.$store.dispatch(this.state+"/back",this.$props.noclear);
        },
    },
    mounted() {
        // this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
    }
};
</script>
<template>
    <div class="d-flex  justify-content-start align-items-center ">
        <!-- <button @click="back()" class="btn btn-outline-transparent mx-2 font-size-14 bold-600 "> 
            <i class="font-size-14 align-middle me-2 dripicons-chevron-left"></i>
            <b>Back</b>
        </button> -->
        <div class="d-flex align-items-center ml-4" style="position:relative">
            <div class="d-flex align-items-center margin-left-5 breadcumb" @click="items.length > 0 ? openFolder() : null">
                <p class="text-left default-text-style fw-500" style="margin-bottom: 0;" :class="items.length >0 ?  'text-grey' : ''">
                    {{state == 'storage' ? 'My Files' : 'Group Files'}}
                </p>
                <!-- <i class=" default-text-style mx-1 mt-2" v-if="items.length > 0"> / </i> -->
            </div>
            <div  class="d-flex align-items-center breadcumb" v-if="onDropDown.length > 0">
                <b-dropdown id="dropdown-1"  text="Dropdown Button" class="m-md-2 bg-transparent more-menu" >
                    <template #button-content>
                        <img src="@/assets/images/icon/More.png" alt />
                    </template>
                    <b-dropdown-item v-for="link in onDropDown" :key="link._id" @click="breadClick(link)" >{{link.file_name}}</b-dropdown-item>
                </b-dropdown>
                <i class="bx bx-chevron-right font-size-16"></i>
            </div>
            <div class="d-flex align-items-center breadcumb" v-for="(item, index) in items" :key="index">
                <i class=" default-text-style mx-2"> > </i>
                <p class="text-left default-text-style fw-500" style="margin-bottom: 0;" :class="index == items.length - 1 ? '' : 'text-grey'" @click="index == items.length - 1 ? null : breadClick(item)">{{item.file_name.substring(0,15)}}{{item.file_name.length > 15 ? '...' : ''}}</p>
            </div>
        </div>
    </div>
</template>