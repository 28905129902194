<template>
    <b-modal id="modalCopyPersonal" no-fade scrollable no-close-on-backdrop content-class="shadow"  header-class="flex-column mb-0 pb-0 no-border"    centered title="Uploading 1 Item">
        <template #modal-header>
            <div class="d-flex justify-content-start align-content-center">
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.svg" alt width="100%" data-toggle="tooltip" title="Close"/>
                </span>
                <p class="ml-3 default-text-style-encrypt">Double-click the folder to select</p>
                <!-- <p class="ml-3 default-text-style-encrypt">Double-click the folder to select {{ selectedField.length }}</p> -->
            </div>
        </template>

        <hr class="horizontal-border"/>
            <Breadcumb noclear="noclear"/>
        <div class="d-flex flex-column mt-0">
            <b-form action="" @submit.prevent="submitEvent" v-if="showCreate" class="mt-10px create-folder-cm-v3">
              <b-form-group id="input-group-1" class="pt-3 create-folder-cm-v3" label-class="label-input"  label-for="input-1">
                <b-form-input 
                    id="input-1"
                    required
                    :state="stateFolderName"
                    class="form-control create-input-folder-cm-v3 mt-8px default-text-style-copy-data"
                    :class="{'is-invalid': validationStatus($v.folderName)}"
                    v-model.trim="$v.folderName.$model" 
                    type="text"
                    placeholder="Folder name"
                    v-on:keydown="nameValidation($event)"></b-form-input>
                    <b-form-invalid-feedback class="invalid-folder-name">
                        {{ invalidFolderName }}
                    </b-form-invalid-feedback>
                    <div class="ml50px invalid-feedback">
                        <!-- <span v-if="!$v.folderName.required" class="required-color error-message-v3">Folder name cannot be empty.</span> -->
                        <span v-if="!$v.folderName.maxLength" class="required-color error-message-v3">Folder name max. length is 255 characters.</span>
                    </div>
              </b-form-group>
              <div class="icon-folder-v3">
                    <img src="@/assets/images/icon/Folder.svg" alt="">
                </div>
                <div class="icon-create-folder-v3">
                    <img src="@/assets/images/icon/check-black.svg" alt="" @click="submitEvent" class="cursor-pointer mr-3" data-toggle="tooltip" title="Create">
                    <img src="@/assets/images/icon/cross-black.svg" alt="" @click="cancelCreate()" class="cursor-pointer mr-3" data-toggle="tooltip" title="Cancel">
                </div>
            </b-form>
            <!-- <form action="" @submit.prevent="submitEvent" v-if="showCreate" class="create-folder-v3">
                <input required type="text" v-model="folderName" placeholder="New Folder" class="default-text-style-copy-data form-control create-input-folder-v3" v-on:keydown="nameValidation($event)">
                <div class="icon-folder-v3">
                    <img src="@/assets/images/icon/Folder.svg" alt="">
                </div>
                <div class="icon-create-folder-v3">
                    <img src="@/assets/images/icon/check-black.svg" alt="" @click="submitEvent" class="cursor-pointer mr-3" data-toggle="tooltip" title="Create">
                    <img src="@/assets/images/icon/cross-black.svg" alt="" @click="cancelCreate()" class="cursor-pointer mr-3" data-toggle="tooltip" title="Cancel">
                </div>
            </form> -->
            <b-list-group class="folder-list-group mt-3">
                <b-list-group-item button v-for="(list) in listings" :key="list._id">
                    <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" @dblclick="(counter += 1), doubleClick(list)">
                        <div class="justify-content-start">
                            <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                            <span class="mx-2 default-text-style-copy-data file-name-folder">{{list.file_name}}</span>
                        </div>
                        <img src="@/assets/images/icon/chevLeft.svg" alt="">
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-if="listings.length <= 0" class="ml-4 mt-3">
                <span class="empty-folder-v3">This folder is empty.</span> 
            </div>
        </div>
        
        <template #modal-footer>
            <a href="javascript:void();" @click="Create()" class="font-weight-medium main-color-api bold-600 label-input">
                <span class="default-text-style"><span class="main-color-api">New Folder</span></span>
            </a>
            <button :disabled="loading" v-if="state2 == 'storage'" class="btn-copy-v3" @click="move()">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                <span class="">Copy</span>
            </button>
            <button :disabled="loading" v-else class="btn-copy-v3" @click="moveToPersonal()">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                <span class="">Copy</span>
            </button>
        </template>
    </b-modal>
</template>

<script>
import Breadcumb from "./breadcumbPersonal.vue";
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
    components:{Breadcumb},
    computed:{
        parent_id(){
            return this.$store.getters["storage/parent_id"];
        },
        breadcumbs(){
            return this.$store.getters["storage/breadcumbs"];
        },
        listings(){
            var items = this.$store.getters['storage/items'];
            items = items.filter((v)=>{
                return v.isFolder
            })
            return items;
        },
        hashMove(){
            return this.$store.getters["storage/hashMove"];
        },
        item(){
            return this.$store.getters["storage/item"];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        state(){
            return "storage"
        },
        state2(){
            return this.$store.getters['state/state'];
        }
    },
    data() {
        return {
            loading : false,
            counter : 0,
            folderName: null,
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            showCreate : false,
            lengthItem : 0,
            countItem : 1
        };
    },
    validations: {
      folderName: {
        required,
        maxLength: maxLength(255)
      }
    },
    methods:{
      validationStatus(validation) {
        return typeof validation != undefined ? validation.$error: false
      },
      nameValidation(e){
        if(!e.key.match(/^[a-zA-Z0-9 _-]*$/))
        {
          e.preventDefault();
        }
      },
      doubleClick(item){
        this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
        // this.$store.commit(this.state+"/SET_BREADCUMB",item);
        this.$store.dispatch(this.state+"/listing","noclear")
      },
      back(){
        this.$store.dispatch(this.state+"/back");
      },
      close(){
        for(var i = 0; i < this.selectedField.length; i++) {
            this.selectedField[i].checked = false
        }
        this.$store.commit("storage/SET_ALL_SELECTED", []);
        this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
    },
      moveToPersonal(){
        this.loading = true
        if(this.selectedField.length > 0){
            this.$toasted.show(`Copying ${this.selectedField.length} item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000,
                queue: true
            })
            var promise = this.selectedField.map((v)=>{
                this.lengthItem = this.selectedField.length
                return this.$store.dispatch("workgroup/copyWgToPersonal",{id: v._id, parent_id: this.parent_id})
                .then(()=>{
                    var num = this.countItem++
                    this.$toasted.show(`${num}/${this.lengthItem} Item copied successfully.`,{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                })
                .catch(()=>{
                    for(var i = 0; i < this.selectedField.length; i++) {
                        this.selectedField[i].checked = false
                    }

                    this.$store.commit("storage/SET_ALL_SELECTED",[]);
                    this.$toasted.show('Item failed to copy. Please try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })

                })
            })
            Promise.all(promise)
            .then((res)=>{
                this.lengthItem = 0
                this.countItem = 1
                this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
                this.selectWG = false
                this.search = ''
                this.loading = false
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
            })
            .catch((err) => {
                this.lengthItem = 0
                this.countItem = 1
                this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
                this.selectWG = false
                this.search = ''
                this.loading = false
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
            })
        }
        else {
            this.$toasted.show(`Copying 1 item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000,
                queue: true
            })
            this.$store.dispatch("workgroup/copyWgToPersonal", {id: this.item._id, parent_id: this.parent_id})
            .then((result) => {
                this.$toasted.show('Item copied successfully.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    queue: true
                })
                this.loading = false
                this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
                this.$store.commit("storage/SET_ALL_SELECTED",[]);
            })
            .catch((err) => {
                for(var i = 0; i < this.selectedField.length; i++) {
                        this.selectedField[i].checked = false
                    }

                    this.$store.commit("storage/SET_ALL_SELECTED",[]);
                this.$toasted.show('Item failed to copy. Please try again.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000,
                    queue: true
                })
                this.loading = false
                this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
            })
        }
      },
      move(){
        this.loading = true
        if(this.selectedField.length > 0){
            this.$toasted.show(`Copying ${this.selectedField.length} item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000
            })
            var promise = this.selectedField.map((v)=>{
                if(v.isFolder){
                    this.lengthItem = this.selectedField.length
                    return this.$store.dispatch(this.state+"/copyFolder",v._id)
                    .then(()=>{
                        var num = this.countItem++
                        this.$toasted.show(`${num}/${this.lengthItem} Item copied successfully.`,{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000,
                            queue: true
                        })
                    })
                    .catch((err)=>{
                        for(var i = 0; i < this.selectedField.length; i++) {
                            this.selectedField[i].checked = false
                            

                        }


                        this.$toasted.show('Item failed to copy. Please try again.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000,
                            queue: true
                        })
                        this.$store.commit("storage/SET_ALL_SELECTED",[]);
                    })
                }else{
                    this.lengthItem = this.selectedField.length
                    return this.$store.dispatch(this.state+"/copy",v.hash_name)
                    .then(()=>{
                        var num = this.countItem++
                        this.$toasted.show(`${num}/${this.lengthItem} Item copied successfully.`,{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000,
                            queue: true
                        })
                    })
                    .catch(()=>{
                        for(var i = 0; i < this.selectedField.length; i++) {
                            this.selectedField[i].checked = false
                        }

                        this.$store.commit("storage/SET_ALL_SELECTED",[]);

                        this.$toasted.show('Item failed to copy. Please try again.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000,
                            queue: true
                        })
                    })
                }
            })
            Promise.all(promise)
                .then((res)=>{
                    this.loading = false
                    this.countItem = 1
                    this.lengthItem = 0
                    this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
                    this.$store.commit("storage/SET_ALL_SELECTED",[]);
                })
                .catch((err) => {
                    this.loading = false
                    this.countItem = 1
                    this.lengthItem = 0
                    this.$root.$emit('bv::hide::modal', 'modalCopyPersonal');
                    this.$store.commit("storage/SET_ALL_SELECTED",[]);
                })
        } else{
            this.$toasted.show(`Copying 1 item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000
            })
            if(this.item.isFolder){
                this.$store.dispatch(this.state+"/copyFolder",this.item._id)
                .then(()=>{
                    this.$toasted.show('Folder copied.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                    this.loading = false
                })
                .catch((err) => {
                    this.$toasted.show('Folder failed to copy. Please try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                    this.loading = false
                })
            } else{
                this.$store.dispatch(this.state+"/copy",this.hashMove).then(()=>{
                    this.$toasted.show('File copied.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                    this.loading = false
                })
                .catch((err) => {
                    for(var i = 0; i < this.selectedField.length; i++) {
                        this.selectedField[i].checked = false
                    }

                    this.$store.commit("storage/SET_ALL_SELECTED",[]);

                    this.$toasted.show('File failed to copy. Please try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000,
                        queue: true
                    })
                    this.loading = false
                })
            }
            this.$root.$emit('bv::hide::modal', 'modalCopyPersonal'); 
        }

      },
        submitEvent() {
            this.stateFolderName = true;
            if(!this.folderName && this.$v.$invalid) {
                // this.invalidFolderName = "Folder name cannot be empty";
                this.stateFolderName = false;
                this.$toasted.show('Folder name cannot be empty',{ 
                theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            } else if(this.$v.$invalid && this.folderName.split('').length > 255) {
                this.$toasted.show('Folder name max. length is 255 characters.',{ 
                theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            } else {
                return (
                    this.$store.dispatch("storage/newFolder",{
                        folder_name: this.folderName,
                        parent_id : this.parent_id
                    })
                    // eslint-disable-next-line no-unused-vars
                    .then(token => {
                        this.validFolderName = token;
                        this.stateFolderName = true;
                        this.$toasted.show('Folder created successfully.',{ 
                        theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.showCreate = false;
                        this.$store.dispatch(this.state+"/listing","noclear")
                        this.$root.$emit('bv::hide::modal', 'modalContainer')
                        this.folderName = null
                    })
                    .catch(error => {
                        // this.invalidFolderName = error ? error : "";
                        this.stateFolderName = false;
                        this.$toasted.show('Folder already exist. Try another name.',{ 
                        theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                    })
                );
            }
        },
        cancelCreate(){
            this.showCreate = false;
        },
        Create(){
            this.showCreate = true;
            this.invalidFolderName = ""
            this.stateFolderName = true;
        }
    },
}
</script>

<style scoped>
.mt-8px{
    margin-top: -6px;
}
/* .ml50px{
    margin-left: 50px;
}
.is-invalid{
    padding-right: 72px;
}
.invalid-feedback{
    margin-left: 50px;
} */
/* #input-group-1 .invalid-feedback{
    margin-left: 50px;
} */
/* .invalid-folder-name{
    width: auto;
} */
.is-valid{
    border: 2px solid #00AAFF;
    box-shadow: none;
    background-image: none;
    padding-right: 77px !important;
}
.is-valid:focus{
    border: 2px solid #00AAFF;
    box-shadow: none;
}

</style>