
<script>

import Breadcumb from "./breadcumbWorkgroup.vue";
export default {
  components:{Breadcumb},
    computed:{
        parent_id(){
            return this.$store.getters["workgroup/parent_id"];
        },
        breadcumbs(){
            return this.$store.getters["workgroup/breadcumbs"];
        },
        listings(){
            var items = this.$store.getters['workgroup/items'];
            items = items.filter((v)=>{
                return v.isFolder
            })
            return items;
        },
        hashMove(){
            return this.$store.getters["workgroup/hashMove"];
        },
        item(){
            return this.$store.getters["workgroup/item"];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        selectedFieldWG() {
            return this.$store.getters['workgroup/selected']
        },
        state(){
            // return this.$store.getters['state/state'];
            return 'workgroup'
        },
        workList() {
            var lists = this.$store.getters['workgroup/workgroups'];
            return lists
        },
        selectedWorkgroup() {
            return this.$store.getters['workgroup/workgroup']
        },
        filteredWorkgroup() {
            var itemsSelected = this.$store.getters['workgroup/selected']
            var itemSelected = this.$store.getters['workgroup/item']
            return this.workList.filter((workgroup) => {
                if((itemsSelected != null && itemsSelected[0].isFolder == true) || (itemSelected != null && itemSelected.isFolder == true)) {
                    return workgroup.title.toLowerCase().match(this.search.toLowerCase()) && workgroup.type != 'PRIVATE'
                } else {
                    return workgroup.title.toLowerCase().match(this.search.toLowerCase())
                }
            })
        }
    },
    data() {
        return {
            loading : false,
            counter : 0,
            folderName: "Folder New",
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            showCreate : false,
            selectWG : false,
            search : '',
            countItem : 1
        };
    },
    methods:{
      clickers(item){
        this.counter++

        if (this.counter === 1) {
          this.clickTimer = setTimeout(() => {
            this.counter = 0
            
            this.$store.dispatch("workgroup/retrieve",item._id)
            .then((result) => {
            })
            .catch((err) => {
            })
          }, 300)
        } else if (this.counter === 2) {
          clearTimeout(this.clickTimer)
          this.counter = 0
          this.doubleClickWG(item)
        }
      },
      selectWg(item){
        if(item == null){
            item = this.selectedWorkgroup
            this.openWorkgroup(item)
        }
      },
      doubleClickWG(item){
        this.openWorkgroup(item)
      },
      openWorkgroup(item){
        // this.$ga.event('workgroup', 'actionx', 'labelx', 1)
        this.$store.commit(this.state+"/SET_KEYWORD","");
        this.$store.commit(this.state+"/SET_PARENT_ID","");
        this.$store.commit(this.state+"/DELETE_BREADCUMB",[]);
        this.$store.commit("workgroup/SET_SELECTED", this.selectedField)
        this.$store.dispatch("workgroup/retrieve",item._id)
        .then(()=>{
            if(this.$router.currentRoute.name != "home"){
            this.$router.push({name:"home"}).catch(()=>{});
            }
            this.$forceUpdate();
            // this.$store.dispatch("workgroup/showDetails");
        });
        this.selectWG = true
        },
      doubleClick(item){
        this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
        // this.$store.commit(this.state+"/SET_BREADCUMB",item);
        this.$store.dispatch(this.state+"/listing","noclear")
      },
      back(){
        this.$store.dispatch(this.state+"/back");
      },
      close(){
            for(var i = 0; i < this.selectedField.length; i++) {
                this.selectedField[i].checked = false
            }
            this.$store.commit("storage/SET_ALL_SELECTED", []);
            this.$store.commit(this.state+"/SET_WORKGROUP", null);
            
            this.$root.$emit('bv::hide::modal', 'modalCopyWorkgroup');
            this.selectWG = false
            this.search = ''
      },
      move(){
        this.loading = true
        if(this.selectedFieldWG.length > 0){
            this.$toasted.show(`Copying ${this.selectedFieldWG.length} item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000
            })
            var promise = this.selectedFieldWG.map((v)=>{
                return this.$store.dispatch(this.state+"/copyToWG",{id: v._id, parent_id: this.parent_id})
                .then(() => {
                    var num = this.countItem++
                    this.$toasted.show(`${num}/${this.selectedFieldWG.length} Item copied successfully.`,{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
                .catch((err) => {
                    this.$toasted.show(err,{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                })
            })
            Promise.all(promise)
            .then((res)=>{
                this.$store.dispatch(this.state + '/listing')
                this.$root.$emit('bv::hide::modal', 'modalCopyWorkgroup');
                this.selectWG = false
                this.search = ''
                this.countItem = 1
                this.loading = false
                this.$store.commit("workgroup/SET_SELECTED",null);
            })
            .catch((err) => {
                this.$store.dispatch(this.state + '/listing')
                this.$root.$emit('bv::hide::modal', 'modalCopyWorkgroup');
                this.$store.commit("workgroup/SET_SELECTED",null);
                this.selectWG = false
                this.search = ''
                this.countItem = 1
                this.loading = false
            })
        }
        else {
            this.$toasted.show(`Copying 1 item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : 1000
            })
            this.$store.dispatch(this.state+"/copyToWG", {id: this.item._id, parent_id: this.parent_id})
            .then((result) => {
                this.$toasted.show('Item copied successfully.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
                this.$store.dispatch('storage/listing')
                this.$root.$emit('bv::hide::modal', 'modalCopyWorkgroup');
                this.selectWG = false
                this.search = ''
                this.loading = false
            })
            .catch((err) => {
                this.$toasted.show(err,{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
                this.$store.dispatch('storage/listing')
                this.$root.$emit('bv::hide::modal', 'modalCopyWorkgroup');
                this.selectWG = false
                this.search = ''
                this.loading = false
            })
        }
      },
        submitEvent() {
            this.stateFolderName = true;
            return (
                this.$store.dispatch("workgroup/newFolder",{
                    folder_name: this.folderName,
                    parent_id : this.parent_id
                })
                // eslint-disable-next-line no-unused-vars
                .then(token => {
                    this.validFolderName = token;
                    this.stateFolderName = true;
                    this.showCreate = false;
                    this.$store.dispatch(this.state+"/listing","noclear")
                    this.$root.$emit('bv::hide::modal', 'modalContainer')
                })
                .catch(error => {
                    this.invalidFolderName = error ? error : "";
                    this.stateFolderName = false;
                })
            );
        },
        cancelCreate(){
            this.showCreate = false;
        },
        Create(){
            this.showCreate = true;
            this.invalidFolderName = ""
            this.stateFolderName = true;
        }
    },
    mounted() {
        this.$store.getters['storage/selected']
        this.$store.dispatch("workgroup/workgroupList");
    }
};
</script>
<style>

</style>
<template>
    <b-modal id="modalCopyWorkgroup" no-fade scrollable no-close-on-backdrop content-class="shadow"  header-class="flex-column mb-0 pb-0 no-border" centered title="Uploading 1 Item">
        <template #modal-header>
            <div class="d-flex justify-content-start align-content-center">
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.png" alt width="100%" data-toggle="tooltip" title="Close"/>
                </span>
                <p class="ml-3 default-text-style-encrypt" v-if="!selectWG">Double-click the workgroup name to select</p>
                <p class="ml-3 default-text-style-encrypt" v-else>Double-click the folder to select</p>
                <!-- <p class="ml-3 default-text-style-encrypt">Copy to Workgroup {{ selectedField.length }}</p> -->
            </div>
        </template>

        <hr class="horizontal-border"/>

        <p v-if="selectWG" class="pl-4 default-text-style-encrypt title-wg-copy-v3 mb-3">{{selectedWorkgroup.title}}</p>
        <Breadcumb v-if="selectWG" noclear="noclear" class="pl-4"/>
        <p v-else class="pl-4 default-text-style-encrypt">Select Workgroup</p>
        <div class="d-flex flex-column mt-0">
            <div v-if="!selectWG">
                <div class="search-box-copy-wg-v3">
                    <input type="text" v-model="search" placeholder="Search" class="form-control search-copy-workgroup">
                    <img src="@/assets/images/icon/search-black.svg" alt="">
                </div>
                <b-list-group class="folder-list-group-wg">
                    <!-- <b-list-group-item button v-for="(work,index) in filteredWorkgroup" :key="index"  @dblclick="(counter += 1), doubleClickWG(work)"> -->
                    <b-list-group-item button v-for="(work,index) in filteredWorkgroup" :key="index" @click.exact="clickers(work)" class="list-workgroup-copy-v3">
                        <div class="d-flex justify-content-between align-items-center cursor-pointer py-1">
                            <span class="mx-2  default-text-style file-name-folder">{{work.title}}</span>
                            <img src="@/assets/images/icon/chevLeft.svg" alt="">
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-else>
                <b-list-group class="mt-2 folder-list-group">
                    <!-- {{ selectedFieldWG.length }} -->
                    <b-list-group-item button v-for="(list,index) in listings" :key="index"  @dblclick="(counter += 1), doubleClick(list)">
                        <div class="d-flex justify-content-between align-items-center cursor-pointer py-1" @dblclick="(counter += 1), doubleClick(list)">
                            <div class="justify-content-start file-name-folder">
                                <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                                <span class="mx-2 default-text-style-copy-data">{{list.file_name}}</span>
                            </div>
                            <img src="@/assets/images/icon/chevLeft.svg" alt="">
                        </div>
                    </b-list-group-item>
                </b-list-group>
            </div>
            <div v-if="listings.length <= 0 && selectWG" class="ml-4 mt-3">
                <span class="empty-folder-v3">This folder is empty.</span>
            </div>
        </div>
        
            <template #modal-footer>
                <!-- <span v-if="!selectWG"></span> -->
                <button v-if="!selectWG" :disabled="selectedWorkgroup == null" :class="selectedWorkgroup == null ? 'disabled-btn-copy-v3' : 'btn-copy-v3'" @click="selectWg()">
                    <span>Select</span>
                </button>
                <button v-else :disabled="loading" class="btn-copy-v3" @click="move()">
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                    <span>Copy</span>
                </button>
            </template>
    </b-modal>
</template>