<template>
    <div class="d-flex justify-content-center flex-column align-items-center suspends">
        <img src="@/assets/images/suspend-img.svg" style="height:128px; width: 128px;" class="my-3" alt="">
        <span class="mb-2">{{messageTitle}}</span>
        <span class="mb-3"><span>{{subMessage}}</span></span>
        <button v-if="linkUpdatePayment != null" @click="updatePayment(linkUpdatePayment)">Update Payment</button>
        <button v-if="linkUpdatePayment == null" @click="gotToPrimary()">Back to Primary</button>
    </div>
</template>

<script>
export default {
    computed: {
        state(){
            return this.$store.getters['state/state']
        },
        linkUpdatePayment(){
            return this.$store.getters['user/linkRenewPayment']
        },
        messageTitle(){
            return this.$store.getters['user/messageSuspend']
        },
        subMessage(){
            return this.$store.getters['user/subMessageSuspend']
        }
    },
    methods: {
        updatePayment(link){
            window.open(link,"_self")
        },
        gotToPrimary(){
            window.open(process.env.VUE_APP_MAIN_DOMAIN,"_self")
        }
    }
}
</script>

<style>

</style>