<template>
  <div>
    <b-modal id="modal-decrypt" class="modal-class-decrypt" no-close-on-backdrop centered hide-header hide-footer>
        <p class="default-text-style-encrypt">This file is password protected</p>
        <p class="mb-4 default-text-style-encrypt-body">Please enter the file password to decrypt it.</p>
        
        <form action="">
            <div class="form-control-encrypt">
                <input
                    id="password-decrypt"
                    placeholder="Enter file password"
                    :type="passwordState"
                    class="form-input-encrypt"
                    v-model.trim="$v.password.$model"
                    :class="{'is-invalid':$v.password.$error, 'is-valid':!$v.password.$invalid}">
                <img v-if="passwordState == 'password'" src="@/assets/images/icon/fi-rr-eye-close.svg" alt="" @click="showPassword()">
                <img v-else src="@/assets/images/icon/fi-rr-eye-open.svg" alt="" @click="showPassword()">
                <div class="invalid-feedback ml-3">
                    <span v-if="!$v.password.required">File password is required.</span>
                </div>
            </div>
        </form>
        <div class="btn-modal-encrypt">
            <button class="mr-3 btn-close" @click="close()">Cancel</button>
            <button class="mr-2 btn-encrypt" :disabled="$v.$invalid" @click="decryptBtn()">Decrypt</button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import moment from 'moment'

export default {
    name: 'Decrypt',
    props: ['item'],
    data() {
        return {
            password: '',
            passwordState: 'password',
            maxPassLength: 18
        }
    },
    validations: {
        password: {
            required
        }
    },
    computed: {
        listing: {
            // getter
            get: function () {
                return this.$store.getters[this.$store.getters['state/state']+'/items'];
            },
            // setter
            set: function (newValue) {
                this.$store.commit(this.$store.getters['state/state']+"/SET_ITEMS",newValue);
            }
        },
        detail(){
            return this.$store.getters[this.$store.getters['state/state']+'/item']
        },
        user_id(){
            return this.$store.getters['user/id']
        },
        state(){
            return this.$store.getters['state/state'];
        },
        showBreadCumb() {
          return this.$store.getters['storage/showBreadcumb']
        }
    },
    methods: {
        refresh(){
            this.$store.dispatch(this.state+"/listing",this.showBreadCumb ? "" : '1')
        },
        showPassword() {
            if(this.passwordState === 'password'){
                this.passwordState = 'text'
            } else {
                this.passwordState = 'password'
            }
        },
        close() {
            this.password = ''
            this.$root.$emit('bv::hide::modal', 'modal-decrypt')
        },
        decryptBtn() {
            this.$ga.event('decrypt_file', 'actionx', 'labelx', 1)
            // document.getElementById("decryptLoading").style.display = "block";
            var idx = this.listing.findIndex((v)=>{
                return v._id == this.item._id
            })
            var detailX = this.listing[idx]

            detailX.locked = true
            detailX.encrypt = 4
            
            if(detailX.locked == true){
                var payload = {
                    password: this.password,
                    id: this.item._id,
                    directory: this.item.directory
                }
                // this.item.encrypt =  { code: 4, status: "progress decrypt" }
                // this.$store.commit('decrypt/ADD_PROGRESS', this.item)
                this.$store.dispatch('decrypt/setDecrypt', payload)
                .then(()=>{
                    this.$toasted.show('Decryption will begin immediately.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    
                    this.$root.$emit('bv::hide::modal', 'modal-decrypt')
                })
                // var firefoxAgent = this.$browserDetect.isFirefox
                // var safariAgent = this.$browserDetect.isSafari
                // var operaAgent = this.$browserDetect.isOpera
                if(this.state == 'workgroup'){
                // if((firefoxAgent || safariAgent || operaAgent) && this.state == 'workgroup'){
                    var index = this.listing.findIndex((v)=>{
                        return v._id == this.item._id
                    })
                    var detail = this.listing[index]
                    detail.locked = true
                    if(detail.locked == true){
                        var intervalWg = setInterval(()=>{
                            this.$store.dispatch('storage/fileretrieve',{_id:this.item._id, directory: this.item.directory})
                            .then((res)=>{
                                if(res.locked != true){
                                    this.$store.dispatch('notif/getAllNotification')
                                    clearInterval(intervalWg)
                                    var payload = {}
                                    payload.body = {
                                        datafile: {
                                            extension: res.extension,
                                            parent_id: res.parent_id,
                                            file_name: res.file_name,
                                            _id: res._id
                                        },
                                        group_by: null,
                                        icon: '',
                                        isread: false,
                                        time: Math.floor(Date.now()/1000),
                                        userid: this.user_id,
                                        id: res._id+'-'+moment().unix()
                                    }
                                    if(res.encrypt == 5){
                                        payload.body.message = `${res.file_name} is successfully decrypted`
                                        payload.body.title = 'Decryption success'
                                        payload.body.datafile.encrypt = 5
                                        // payload.body.datafile.encrypt = {code: 5, status: 'decrypted'}
                                    } else if(res.encrypt == 6){
                                        payload.body.message = `${res.file_name} failed to decrypt`
                                        payload.body.title = 'Decryption failed'
                                        payload.body.datafile.encrypt = 6
                                        // payload.body.datafile.encrypt = {code: 6, status: 'decrypted failed'}
                                    } else if(res.encrypt == 7){
                                        payload.body.message = `${res.file_name} failed to decrypt`
                                        payload.body.title = 'Decryption failed'
                                        payload.body.datafile.encrypt = 7
                                        // payload.body.datafile.encrypt = {code: 7, status: 'wrong key!'}
                                    }
                                    this.$store.dispatch('firebase/setNotifFirebase', payload.body)
                                    .then(()=>{
                                        this.refresh()
                                    })
                                }
                            })
                        },2000)
                    }
                } else {
                    // if(firefoxAgent || safariAgent || operaAgent){
                        var index2 = this.listing.findIndex((v)=>{
                            return v._id == this.item._id
                        })
                        var detail2 = this.listing[index2]
                        detail2.locked = true
                        
                        if(detail2.locked == true){
                        // if(firefoxAgent || safariAgent || operaAgent && detail2.locked == true){
                            var interval = setInterval(()=>{
                                this.$store.dispatch('storage/fileretrieve',{_id:this.item._id, directory: this.item.directory})
                                .then((res)=>{
                                    if(res.locked != true){
                                        this.$store.dispatch('notif/getAllNotification')
                                        clearInterval(interval)
                                        var payload = {}
                                        payload.body = {
                                            datafile: {
                                                extension: res.extension,
                                                parent_id: res.parent_id,
                                                file_name: res.file_name,
                                                _id: res._id
                                            },
                                            group_by: null,
                                            icon: '',
                                            isread: false,
                                            time: Math.floor(Date.now()/1000),
                                            userid: this.user_id,
                                            id: res._id+'-'+moment().unix()
                                        }
                                        if(res.encrypt == 5){
                                            payload.body.message = `${res.file_name} is successfully decrypted`
                                            payload.body.title = 'Decryption success'
                                            payload.body.datafile.encrypt = 5
                                            payload.body.workgroup = 'Personal'
                                            // payload.body.datafile.encrypt = {code: 5, status: 'decrypted'}
                                        } else if(res.encrypt == 6){
                                            payload.body.message = `${res.file_name} failed to decrypt`
                                            payload.body.title = 'Decryption failed'
                                            payload.body.datafile.encrypt = 6
                                            payload.body.workgroup = 'Personal'
                                            // payload.body.datafile.encrypt = {code: 6, status: 'decrypted failed'}
                                        } else if(res.encrypt == 7){
                                            payload.body.message = `${res.file_name} failed to decrypt`
                                            payload.body.title = 'Decryption failed'
                                            payload.body.datafile.encrypt = 7
                                            payload.body.workgroup = 'Personal'
                                            // payload.body.datafile.encrypt = {code: 7, status: 'wrong key!'}
                                        }
                                        this.$store.dispatch('firebase/setNotifFirebase', payload.body)
                                        .then(()=>{
                                            this.refresh()
                                        })
                                    }
                                })
                            },2000)
                        }
                    // }
                }
                this.password = null
            }
        }
    }
}
</script>

<style scoped>
input::-ms-reveal,
input::-ms-clear {
    display: none !important;
}
</style>