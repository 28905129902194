<template>
    <b-modal id="modal-rename-v3" no-close-on-backdrop hide-header hide-footer centered>
        <span class="modal-rename-text-btn dark-text">Rename {{item.isFolder ? 'folder' : 'file'}}</span>
         <b-form class="mt-3" @submit.prevent="submitEvent">
            <b-form-group :invalid-feedback="invalidRename" :state="stateRename" id="input-group-1" class="py-2" label-class="label-input" label-for="input-1">
                <div class="form-input-name-file">
                    <b-form-input 
                        id="input-1"
                        required
                        v-model="valueNameInput.file_name"
                        class="input-rename-box-v3"
                        type="text"
                        placeholder="File New"></b-form-input>
                </div>
            </b-form-group>
            <div class="mt-2 pt-2 d-flex justify-content-end align-items-center">
                <a @click="close()" class="cursor-pointer modal-rename-text-btn mr-3"><span class="main-color-api">Cancel</span></a>
                <button class="btn-modal-rename-v3 main-btn-api">
                    <!-- <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> -->
                    <span class="modal-rename-text-btn text-white">Save</span>
                </button>
            </div>
        </b-form>
        <!-- <pre>{{ item }}</pre> -->
    </b-modal>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            valueRename: '',
            stateRename: true,
            invalidRename: ''
        }
    },
    computed: {
        state() {
            return this.$store.getters['state/state'];
        },
        item: {
            // return this.$store.getters[this.$store.getters['state/state']+'/item']
            cache: false,
            get() {
                return this.$store.getters[this.state+"/item"]
            }
        },
        valueNameInput: {
            get() {
                var item = this.$store.getters[this.state+"/item"]
                return item
                // return item.extension === '' ? item.file_name : item.file_name.split('.').slice(0, -1).join('.')
            },
            set(newValue) {
                var item = this.$store.getters[this.state+"/item"]
                item.file_name = newValue
                this.$store.commit(this.state+'/SET_ITEM', item)
            }
        },
    },
    beforeMount(){
    },
    methods: {
        close() {
            this.$store.dispatch('user/getLimit', null)
            this.$bvModal.hide('modal-rename-v3')
            this.stateRename = true
            this.$store.commit("error/SET_ERROR", false)
            this.$store.commit("error/SET_MESSAGE", '')
        },
        submitEvent(){
            this.$store.dispatch(this.state+"/rename", this.item)
            .then(() => {
                this.$store.commit(this.state+'/SET_RENAME_ITEM', '')
                this.$bvModal.hide('modal-rename-v3')
                if(this.item.isFolder){
                    this.$toasted.show('Folder updated successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                } else {
                    this.$toasted.show('File updated successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                }
            })
            .catch((err) => {
                this.stateRename = false
                this.invalidRename = err
                if(this.item.isFolder){
                    this.$toasted.show('Folder failed to update. Make sure your internet is connected, then try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                } else {
                    this.$toasted.show('File failed to update. Make sure your internet is connected, then try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                }
            })
            // var value = this.valueRenameItem
        },
    },
}
</script>

<style scoped>
.form-input-name-file{
    position: relative !important;
}
.modal-rename-text-btn{
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    letter-spacing: -1px;
}
.btn-modal-rename-v3{
    width: 69px;
    height: 40px;
    padding: 8px;
    border-radius: 4px;
    border: none;
}
.dark-text{
    color: #262A2C;
}
</style>