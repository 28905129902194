<template>
  <b-modal id="modal-send-to-chat" class="modal-class-decrypt" no-close-on-backdrop centered hide-header hide-footer>
    <div class="d-flex flex-column">
      <span class="title-modal-send-chat">Send file to chat</span>
      <div class="files-modal-chat-info d-flex justify-content-start align-items-center my-3 box-info-send-chat">
        <img :src="require(`@/assets/images/icon/${files.icon}`)" alt="" height="100%">
        <div class="d-flex flex-column ml-2">
          <span class="info-file-name-send-chat text-wrap-v3">{{files.file_name}}</span>
          <span class="info-file-size-send-chat">{{files.size}}</span>
        </div>
        <!-- {{files}} -->
      </div>
      <b-form-group id="msg-send-chat">
        <b-form-textarea class="login-input-style input-msg-send-chat" placeholder="Message" v-model="msg"  rows="4"></b-form-textarea>
      </b-form-group>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <span class="cancel-send-chat-btn cursor-pointer" @click="close()">Cancel</span>
      <button class="submit-send-chat-btn" @click="sendFileToChat()">Send</button>
    </div>
    <!-- Modal send file and message -->
    <!-- {{user_id}} & {{workgroup}} -->
    <!-- <pre>{{item}}</pre> -->
  </b-modal>
</template>

<script>
export default {
  name: 'ModalSendFileChat',
  props: ["item"],
  data() {
    return {
      msg: ""
    }
  },
  computed: {
    user_id(){
      return this.$store.getters['user/id']
    },
    workgroup(){
      return this.$store.getters['workgroup/workgroup']
    },
    files(){
      return this.$store.getters['storage/item']
    }
  },
  methods: {
    close(){
      this.$ga.event(process.env.VUE_APP_GA_WG_GF_SEND_FILE_TO_CHAT_CANCEL, 'WG - Send File to Chat Button Cancel', 'wg-access-file')
      this.msg = ""
      this.$root.$emit('bv::hide::modal', 'modal-send-to-chat')
    },
    sendFileToChat(){
      this.$ga.event(process.env.VUE_APP_GA_WG_GF_SEND_FILE_TO_CHAT_SEND, 'WG - Send File to Chat Button Send', 'wg-access-file')
      var payload = {
        user: this.user_id,
        group: this.workgroup._id,
        message: this.msg,
        file_id: this.files._id
      }
      this.$store.dispatch('chat/isConnectedSocket')
        .then((res)=>{
            if(res == false){
                this.$store.dispatch('user/getSocket', {root: true})
                // .then(() => {
                //     this.$store.dispatch('chat/openChat')
                // })
            }
            this.$store.dispatch('workgroup/sendFilesToChat', payload)
            .then(()=> {
              this.$root.$emit('bv::hide::modal', 'modalSendFileToChat')
              this.$root.$emit('bv::hide::modal', 'modal-send-to-chat')
              if(this.$router.name != 'chat'){
                this.$router.push({path:'/chat', query: {wgId: this.workgroup._id}}).catch(()=>{})
              }
            })
      })
    }
  }
}
</script>

<style>
#msg-send-chat{
  margin: 0;
}
.title-modal-send-chat{
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  color: #262A2C;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
}
.box-info-send-chat{
  background: #F6F6FC;
  border-radius: 64px;
  padding: 8px 16px;
  width: fit-content;
}
.info-file-name-send-chat{
  font-family: 'Plus Jakarta Sans';
  font-weight: 400;
  color: #262A2C;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 0.25rem;
}
.info-file-size-send-chat{
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  color: #919394;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.02em;
}
.input-msg-send-chat::placeholder{
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  color: #919394;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
}
.input-msg-send-chat{
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  color: #262A2C;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  padding: 16px;
  margin-bottom: 24px;
}
</style>