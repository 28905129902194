
<script>

import Switches from "vue-switches";
export default {
  components: {
    Switches
  },
    computed:{
        email() {
            return this.$store.getters['user/email']
        },
        parent_id(){
            return this.$store.getters["storage/parent_id"];
        },
        item(){
            return this.$store.getters["storage/item"];
        },
        loading(){
            return this.$store.getters["share/loading"];
        },
        selectedAccess(){
            return this.accessTypes.filter(v=>v.selected)[0]
        },
        link(){
            return location.protocol + '//' + location.host+"/shared/?key="+this.item._id+"&directory="+this.item.directory
        },
        enableds:{
            cache: false,
            get(){
                return this.$store.getters["storage/publicShare"];
            },
            set(){
                const enbld = this.$store.getters["storage/publicShare"];
                this.$store.commit("storage/SET_PUBLIC_SHARE",enbld);
            }
        }
    },
    data() {
        return {
            counter : 0,
            enabled : false,
            accessTypes : [
                {
                    id : "View",
                    label : "can view",
                    selected : true
                },
                {
                    id : "Edit",
                    label : "can edit",
                    selected : false
                }
            ],
            usershare : [],
            emailuser : "",
            showCopied : false,
            validEmail : true,
        };
    },
    methods:{
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        close(){
            this.$root.$emit('bv::hide::modal', 'modalShare');
        },
        selectAccestype(index){
            this.accessTypes = this.accessTypes.map((v,i)=>{
                return {label:v.label,selected:i==index,id:v.id}
            })
        },
        addUserShare(){
            if(this.validateEmail(this.emailuser)){
                this.validEmail = true
                //   this.usershare.push({email:this.emailuser,access:this.selectedAccess})
                this.$store.dispatch("share/add_user",
                {
                    users_id : [{email:this.emailuser,access_type:this.selectedAccess.id}],
                    files_id : this.item._id,
                    directory : this.item.directory
                }
                );
                this.emailuser = ""
            }else{
                this.validEmail = false
            }
        },
        changeAccess(access,index){
            this.usershare[index].access = access;
        },
        changeAccessReady(access,shared,readyUser){
            this.$store.dispatch("share/edit_access",
            {
                id : shared._id,
                email : readyUser.email,
                access_type : access.id,
                file_id : this.item._id,
                directory : this.item.directory
            }
            );
        },
        removeReady(email,id){
            this.$store.dispatch("share/remove_user",
            {
                id,
                email,
                file_id : this.item._id,
                directory : this.item.directory
            }
            );
        },
        copy() {
            var copyText = document.getElementById("link");
            copyText.select();
            copyText.setSelectionRange(0, 99999);
            document.execCommand("copy");
            this.showCopied = true;
        },
        publicShare(e){
            if(e){
                this.$store.dispatch("share/public_share",
                {
                    files_id : this.item._id,
                    directory : this.item.directory
                }
                ).then(()=>{
                    this.$store.commit("storage/SET_PUBLIC_SHARE",e);
                    this.$store.dispatch("storage/fileretrieve",{_id : this.item._id,directory:this.item.directory})
                });
            }else{
                this.$store.dispatch("share/disable_public_share",
                {
                    files_id : this.item._id,
                    directory : this.item.directory
                }
                ).then(()=>{
                    this.$store.commit("storage/SET_PUBLIC_SHARE",e);
                    this.$store.dispatch("storage/fileretrieve",{_id : this.item._id,directory:this.item.directory})
                });
            }
        }
    }
};
</script>
<template>
    <b-modal id="modalShare" no-fade modal-sm  content-class="shadow" :hide-header="true"  centered title="Uploading 1 Item" >
        <div class="d-flex justify-content-between align-items-center">
            <span class="default-text-style bold-normal text-dark">Share with others 👋</span>
            <span class="cursor-pointer px-2" @click="close()">
                <img src="@/assets/images/icon/Close.png" alt width="100%" />
            </span>
        </div>
        <button class="btn btn-shareFiles mt-3 px-4 btn-default d-flex align-items-center " v-if="item != null"> 
            <span class="pr-2">
                <img :src="require(`@/assets/images/icon/${item.icon}`)" alt width="100%" />
            </span>
            <span class="text-dark wraptext default-text-style">{{item.file_name}}</span>
        </button>
        <b-form-group id="input-group-1" class="py-4" v-if="item != null">
            <b-input-group>
                <b-form-input id="input-1" v-model="emailuser" class="login-input-style border-right-none"  type="email" placeholder="Email Address"></b-form-input>
                <template #append>
                    <b-dropdown class="dropdown-input" right>
                        <template #button-content>
                            <span class="text-blue default-text-style"><span class="text-blue">{{selectedAccess.label}}</span></span>
                            <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                        </template>
                        <b-dropdown-item v-for="(access,index) in accessTypes" :key="index" @click="selectAccestype(index)">
                            <i v-if="access.selected" class="dripicons-checkmark px-2  text-blue"></i>
                            <span v-else class="px-3"></span>
                            <span class="text-dark default-text-style">{{access.label}}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>                
            </b-input-group>
            <b-form-invalid-feedback :state="validEmail">
                Your Email Invalid Format
            </b-form-invalid-feedback>
        </b-form-group>
        <div class="user-share-list" v-if="item != null">
            <div class="d-flex justify-content-between align-items-center py-2">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{email.charAt(0).toUpperCase()}}</span>
                    </div>
                    <span class=" default-text-style text-dark px-2">{{email}}</span>
                </div>
                <span class=" default-text-style text-dark">Owner</span>
            </div>
            <div v-for="(shared,si) in item.users_share" :key="si">
                <div class="d-flex justify-content-between align-items-center py-2" v-for="(readyUser,ri) in shared.users_id" :key="ri">
                    <div class="d-flex justify-content-start align-items-center">
                        <div class="avatar-xs">
                            <span class="avatar-title rounded-circle">{{readyUser.email.charAt(0).toUpperCase()}}</span>
                        </div>
                        <span class=" default-text-style  text-dark px-2 ">{{readyUser.email}}</span>
                    </div>
                    <b-dropdown class="dropdown-input border-none" left>
                        <template #button-content>
                            <span class="text-dark default-text-style">Edit</span>
                            <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                        </template>
                        <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccessReady(access,shared,readyUser)">
                            <i v-if="access.id == readyUser.access_type" class="dripicons-checkmark px-2  text-blue"></i>
                            <span v-else class="px-3"></span>
                            <span class="text-dark">{{access.label}}</span>
                        </b-dropdown-item>
                        <hr class="mx-2 mt-1 mb-1">
                        <b-dropdown-item>
                            <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                            <span class="text-dark">resend invite</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="removeReady(readyUser.email,shared._id)">
                            <span class="text-danger">remove</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
            <div class="d-flex justify-content-between align-items-center py-2" v-for="(shareuser,index) in usershare" :key="index">
                <div class="d-flex justify-content-start align-items-center">
                    <div class="avatar-xs">
                        <span class="avatar-title rounded-circle">{{shareuser.email.charAt(0).toUpperCase()}}</span>
                    </div>
                    <span class=" default-text-style  text-dark px-2">{{shareuser.email}}</span>
                </div>
                <b-dropdown class="dropdown-input border-none" left>
                    <template #button-content>
                        <span class="text-dark default-text-style ">Edit</span>
                        <i class="dripicons-chevron-down pl-2 mt-2 text-dark"></i>
                    </template>
                    <b-dropdown-item v-for="(access,ia) in accessTypes" :key="ia" @click="changeAccess(access,index)">
                        <i v-if="access.label == shareuser.access.label" class="dripicons-checkmark px-2  text-blue"></i>
                        <span v-else class="px-3"></span>
                        <span class="text-dark">{{access.label}}</span>
                    </b-dropdown-item>
                    <hr class="mx-2 mt-1 mb-1">
                    <b-dropdown-item>
                        <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                        <span class="text-dark">resend invite</span>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <!-- <i class="dripicons-checkmark px-2  text-blue"></i> -->
                        <span class="text-danger">remove</span>
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <br>    
            <div class="d-flex justify-content-end align-items-center mt-4 mx-2">
                <button  class="btn base-btn bg-blue btn-storage"  :disabled="loading" @click="addUserShare">      
                    <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>              
                    <span class="default-text-style">Send Invitation</span>
                </button>
            </div>
        </div>
        <template #modal-footer v-if="item != null">
            <div class="footer-share ">
                <div class="d-flex justify-content-between align-items-center">
                    <h1 class="card-title bold-normal  default-text-style text-dark">Get Link</h1>            
                    <switches v-model="enableds" :emit-on-mount="false" @input="publicShare($event)" v-b-tooltip.hover :title="`${enableds ? 'Disabled' : 'Enabled'} link for share to anyone`" type-bold="true" theme="custom" color="green" class="mb-0"></switches>
                </div>
                <p class="default-text-style"><span class="text-muted"><b>Restricted</b> Copy this link for share to anyone.</span></p>
                <div class="" v-if="enableds">
                    <b-form-group id="input-group-2">
                        <b-input-group>
                            <b-form-input id="link" readonly v-model="link" class="login-input-style text-green border-right-none"  type="text" placeholder="Link"></b-form-input>
                            <b-input-group-append>
                                <b-button  @click="copy()" v-b-tooltip.click title="Copied" class="btn-on-input"><span class="default-text-style"><span class="text-blue">Copy Link</span></span></b-button>
                            </b-input-group-append>             
                        </b-input-group>
                    </b-form-group>
                </div>
            </div>
        </template>
    </b-modal>
</template>