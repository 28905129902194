
<script>
import Breadcumb from "./breadcumb.vue";
import { maxLength, required } from 'vuelidate/lib/validators'

export default {
  components:{Breadcumb},
    computed:{
        parent_id(){
            return this.$store.getters[this.$store.getters['state/state']+"/parent_id"];
        },
        breadcumbs(){
            return this.$store.getters[this.$store.getters['state/state']+"/breadcumbs"];
        },
        listings(){
            var items = this.$store.getters[this.$store.getters['state/state']+'/items'];
            items = items.filter((v)=>{
                return v.isFolder
            })

            return items;
        },
        hashMove(){
            return this.$store.getters[this.$store.getters['state/state']+"/hashMove"];
        },
        item(){
            return this.$store.getters[this.$store.getters['state/state']+"/item"];
        },
        selectedField() {
            return this.$store.getters['storage/selected']
        },
        state(){
            return this.$store.getters['state/state'];
        }
    },
    data() {
        return {
            loading: false,
            counter : 0,
            folderName: null,
            invalidFolderName : "Your account or Email is incorrect.",
            validFolderName : "",
            stateFolderName : true,
            showCreate : false
        };
    },
    validations: {
      folderName: {
        required,
        maxLength: maxLength(255)
      }
    },
    methods:{
      validationStatus(validation) {
        return typeof validation != undefined ? validation.$error: false
      },
      nameValidation(e){
        if(!e.key.match(/^[a-zA-Z0-9 _-]*$/))
        {
          e.preventDefault();
        }
      },
      doubleClick(item){
        if(this.selectedField.filter((v)=>{return v._id == item._id}).length > 0) {
            return;
        }

        this.$store.commit(this.state+"/SET_PARENT_ID",item._id);
        // this.$store.commit(this.state+"/SET_BREADCUMB",item);
        this.$store.dispatch(this.state+"/listing","noclear")
      },
      back(){
        this.$store.dispatch(this.state+"/back");
      },
      close(){
            for(var i = 0; i < this.selectedField.length; i++) {
                this.selectedField[i].checked = false
            }

            this.$store.commit("storage/SET_ALL_SELECTED", []);
            this.$root.$emit('bv::hide::modal', 'modalMove');
      },
      move(){
          this.loading = true
          if(this.selectedField.length > 0){
            this.$toasted.show(`Moving ${this.selectedField.length} item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : this.loading == true
            })
            var promise = this.selectedField.map((v)=>{
              if(this.state == "storage"){
                  if(v.isFolder){
                    this.$store.dispatch(this.state+"/moveFolder",v._id);
                  }else{
                    this.$store.dispatch(this.state+"/move",v.hash_name);
                  }
              } else if(this.state == "workgroup"){
                  var payload = {
                    target_id: v._id,
                    destination_id: this.parent_id
                  }
                  if(v.isFolder){
                    this.$store.dispatch(this.state+"/moveFolder",v._id);
                  }else{
                    this.$store.dispatch(this.state+"/move",payload);
                  }
              }
            })
            Promise.all(promise)
            .then(()=>{
                this.$toasted.show('Item moved successfully.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
                this.loading = false
                this.$root.$emit('bv::hide::modal', 'modalMove')
                this.$store.commit(this.state+"/SET_ALL_SELECTED",[])
            })
            .catch((err) => {
                this.$toasted.show('Item failed to move. Make sure your internet is connected, then try again.',{ 
                    theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
                this.loading = false
                this.$root.$emit('bv::hide::modal', 'modalMove');
                this.$store.commit(this.state+"/SET_ALL_SELECTED",[]);
            });
          }else{
            this.$toasted.show(`Moving 1 item...`,{ 
                theme: "toasted-primary", 
                position: "bottom-center", 
                duration : this.loading == true
            })
              if(this.item.isFolder){
                this.$store.dispatch(this.state+"/moveFolder",this.item._id)
                .then(()=>{
                    this.$toasted.show('Item moved successfully.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    this.loading = false
                })
                .catch((err) => {
                    this.$toasted.show('Item failed to move. Make sure your internet is connected, then try again.',{ 
                        theme: "toasted-primary", 
                        position: "bottom-center", 
                        duration : 2000
                    })
                    this.loading = false
                })
              }else{
                if(this.state == "storage"){
                    this.$store.dispatch(this.state+"/move",this.hashMove)
                    .then(()=>{
                        this.$toasted.show('Item moved successfully.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.loading = false
                    })
                    .catch((err) => {
                        this.$toasted.show('Item failed to move. Make sure your internet is connected, then try again.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.loading = false
                    })
                } else if(this.storage == "workgroup"){
                    var payload2 = {
                        target_id: this.item._id,
                        destination_id: this.parent_id
                    }
                    this.$store.dispatch(this.state+"/move",payload2)
                    .then(()=>{
                        this.$toasted.show('Item moved successfully.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.loading = false
                    })
                    .catch((err) => {
                        this.$toasted.show('Item failed to move. Make sure your internet is connected, then try again.',{ 
                            theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.loading = false
                    })
                }
              }
              this.$root.$emit('bv::hide::modal', 'modalMove');   
          }
      },
        submitEvent() {
            this.stateFolderName = true;
            if(this.folderName == null && this.$v.$invalid) {
                // this.invalidFolderName = "Folder name cannot be empty";
                this.stateFolderName = false;
                this.$toasted.show('Folder name cannot be empty',{ 
                theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            } else if(this.$v.$invalid && this.folderName.split('').length > 255) {
                this.$toasted.show('Folder name max. length is 255 characters.',{ 
                theme: "toasted-primary", 
                    position: "bottom-center", 
                    duration : 2000
                })
            } else {
                return (
                    this.$store.dispatch(this.$store.getters["state/state"]+"/newFolder",{
                        folder_name: this.folderName,
                        parent_id : this.parent_id
                    })
                    // eslint-disable-next-line no-unused-vars
                    .then(token => {
                        this.validFolderName = token;
                        this.stateFolderName = true;
                        this.$toasted.show('Folder created successfully.',{ 
                        theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                        this.showCreate = false;
                        this.$store.dispatch(this.state+"/listing","noclear")
                        this.$root.$emit('bv::hide::modal', 'modalContainer')
                    })
                    .catch(error => {
                        this.invalidFolderName = error ? error : "";
                        this.stateFolderName = false;
                        this.$toasted.show('Folder already exist. Try another name.',{ 
                        theme: "toasted-primary", 
                            position: "bottom-center", 
                            duration : 2000
                        })
                    })
                );
            }
        },
        cancelCreate(){
            this.showCreate = false;
        },
        Create(){
            this.showCreate = true;
            this.invalidFolderName = ""
            this.stateFolderName = true;
        }
    }
};
</script>
<template>
    <b-modal id="modalMove" scrollable  no-fade header-class="flex-column mb-0 pb-0 no-border" no-close-on-backdrop content-class="shadow"  centered title="Uploading 1 Item">

        <template #modal-header>
            <div class="d-flex justify-content-center align-content-center">
                <span class="cursor-pointer px-2" @click="close()">
                    <img src="@/assets/images/icon/Close.svg" alt width="100%" data-toggle="tooltip" title="Close"/>
                </span>
                <p class="ml-3 default-text-style-encrypt">Double-click the folder to select</p>
            </div>
            
        </template>

        <hr class="horizontal-border"/>
        <Breadcumb class="breadcrumb-margin" noclear="noclear"/>

        <div class="d-flex flex-column mt-0">
            <b-form action="" @submit.prevent="submitEvent" v-if="showCreate" class="create-folder-cm-v3">
              <b-form-group id="input-group-1" class="pt-3 create-folder-cm-v3" label-class="label-input"  label-for="input-1">
                <b-form-input 
                    id="input-1"
                    required
                    :state="stateFolderName"
                    class="form-control create-input-folder-cm-v3 mt-8px default-text-style-copy-data"
                    :class="{'is-invalid': validationStatus($v.folderName)}"
                    v-model.trim="$v.folderName.$model" 
                    type="text"
                    placeholder="Folder name"
                    v-on:keydown="nameValidation($event)"></b-form-input>
                    <b-form-invalid-feedback class="invalid-folder-name">
                        {{ invalidFolderName }}
                    </b-form-invalid-feedback>
                    <div class="ml50px invalid-feedback">
                        <!-- <span v-if="!$v.folderName.required" class="required-color error-message-v3">Folder name cannot be empty.</span> -->
                        <span v-if="!$v.folderName.maxLength" class="required-color error-message-v3">Folder name max. length is 255 characters.</span>
                    </div>
              </b-form-group>
              <div class="icon-folder-v3">
                    <img src="@/assets/images/icon/Folder.svg" alt="">
                </div>
                <div class="icon-create-folder-v3">
                    <img src="@/assets/images/icon/check-black.svg" alt="" @click="submitEvent" class="cursor-pointer mr-3" data-toggle="tooltip" title="Create">
                    <img src="@/assets/images/icon/cross-black.svg" alt="" @click="cancelCreate()" class="cursor-pointer mr-3" data-toggle="tooltip" title="Cancel">
                </div>
            </b-form>
            <!-- <form action="" @submit.prevent="submitEvent" v-if="showCreate" class="create-folder-v3">
                <input required type="text" v-model="folderName" placeholder="New Folder" class="default-text-style-copy-data form-control create-input-folder-v3" v-on:keydown="nameValidation($event)">
                <div class="icon-folder-v3">
                    <img src="@/assets/images/icon/Folder.svg" alt="">
                </div>
                <div class="icon-create-folder-v3">
                    <img src="@/assets/images/icon/check-black.svg" alt="" @click="submitEvent" class="cursor-pointer mr-3" data-toggle="tooltip" title="Create">
                    <img src="@/assets/images/icon/cross-black.svg" alt="" @click="cancelCreate()" class="cursor-pointer mr-3" data-toggle="tooltip" title="Cancel">
                </div>
            </form> -->
            <b-list-group class="folder-list-group overflow-auto heightScreen">
                <b-list-group-item button v-for="(list,index) in listings" :key="index" 
                 @dblclick="(counter += 1), doubleClick(list)" 
                :class=" selectedField.filter((v)=>{return v._id == list._id}).length > 0 ? 'folder-list-group-disabled' : ''" >
                    <!-- {{listings}} -->
                    <div v-if="list.isFolder" class="d-flex align-items-center cursor-pointer py-1">
                        <img :src="require(`@/assets/images/icon/${list.icon}`)" alt />
                        <span class="mx-2 default-text-style file-name-folder">{{list.file_name}}</span>
                    </div>
                </b-list-group-item>
            </b-list-group>
            <div v-if="listings.length <= 0" class="ml-4 mt-3">
                <span class="empty-folder-v3">This folder is empty.</span>
            </div>
        </div>
        
        <template #modal-footer class="d-flex">
            <a href="javascript:void();" @click="Create()" class="font-weight-medium main-color-api bold-600 label-input">
                <span class="default-text-style"><span class="main-color-api">New Folder</span></span>
            </a>
            <button :disabled="loading || selectedField.filter((v)=>{return v.parent_id == parent_id}).length > 0" 
                :class="loading || selectedField.filter((v)=>{return v.parent_id == parent_id}).length > 0 ? 'disabled-btn-copy-v3' : 'btn-copy-v3'" @click="move()">
                <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i> 
                <span class="">Move</span>
            </button>
        </template>
    </b-modal>
</template>

<style scoped>
.mt-8px{
    margin-top: -6px;
}
/* .ml50px{
    margin-left: 50px;
}
.is-invalid{
    padding-right: 72px;
}
.invalid-feedback{
    margin-left: 50px;
} */
/* #input-group-1 .invalid-feedback{
    margin-left: 50px;
} */
/* .invalid-folder-name{
    width: auto;
} */
.is-valid{
    border: 2px solid #00AAFF;
    box-shadow: none;
    background-image: none;
    padding-right: 77px !important;
}
.is-valid:focus{
    border: 2px solid #00AAFF;
    box-shadow: none;
}

.breadcrumb-margin {
    /* margin-left: -16px !important; */
    margin-bottom: 10px;
}


</style>