<template>
    <b-modal id="modal-folder-share-v3" centered no-close-on-backdrop hide-header hide-footer>
        <div class="d-flex flex-column justify-content-start align-items-start">
            <img src="@/assets/images/pict-folder-share.svg" alt="" class="mb-4">
            <span class="modal-folder-share-title mb-3">Use workgroup to have a shared folder</span>
            <span class="modal-folder-share-text">Workgroup allows you to have shared folders and gives you more control over who can manage them.</span><br>
            <span class="modal-folder-share-text mb-4">To add a new workgroup, click "Add Workgroup" in the top left menu.</span>
        </div>
        <div class="d-flex justify-content-end align-items-center">
            <a href="https://jaybod.com/workgroup" target="_blank" class="modal-fs-learn mr-4">Learn more</a>
            <button @click="close()" class="modal-fs-btn">Got it</button>
        </div>
    </b-modal>
</template>

<script>
export default {
    methods: {
        close() {
            this.$root.$emit('bv::hide::modal', 'modal-folder-share-v3')
        }
    }
}
</script>
<style>
.modal-folder-share-title{
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: -0.02em;
    color: #262A2C;
}
.modal-folder-share-text{
    font-family: 'Plus Jakarta Sans';
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: -0.02em;
    color: #262A2C;
}

</style>