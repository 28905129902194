<template>
  <b-modal no-close-on-backdrop ref="modal-end-session" id="ended-session" hide-header hide-footer centered>
    <p class="end-session-title mb-3">Your session has expired</p>
    <p class="end-session-subject">Please sign in again to access your Jaybod.</p>
    <div class="justify-content-end d-flex mt-3">
        <a href="/logout"><button class="btn-end-session text-btn-expired">Sign In</button></a>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalEndSession',
  methods: {
    logOut() {
        this.$store.commit("error/SET_MESSAGE","")
        this.$store.commit("error/SET_ERROR",false)
        this.$root.$emit('bv::hide::modal', 'ended-session')
        this.$router.push('/logout')
    }
  }
}
</script>

<style>
.btn-end-session{
    margin-top: 32px;
    border: none;
    display: flex;
    width: 79px;
    background: #00AAFF;
    height: 40px;
    color: #FFFFFF;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -1px;
    font-weight: 400;
}
.text-btn-expired{
}
</style>