
<script>
export default {
  computed: {
    grid(){
        return this.$store.getters['storage/gridView'];
    },
  },
};
</script>
<template>
    <tbody v-if="!grid">
        <tr v-for="loop in 12" :key="loop">
            <td>
                <div class="d-flex align-items-center">
                    <b-skeleton type="avatar"></b-skeleton>
                    <b-skeleton width="70%" class="mx-2" height="20px"></b-skeleton>
                </div>
            </td>
            <td  class="text-center">
                <b-skeleton width="100%" class="mx-2" height="20px"></b-skeleton>
            </td>
            <td  class="text-center">
                <b-skeleton width="100%" class="mx-2" height="20px"></b-skeleton>
            </td>
            <td>
                <b-skeleton width="100%" class="mx-2" height="20px"></b-skeleton>
            </td>
        </tr>
    </tbody>
</template>